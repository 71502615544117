








































import { Vue, Component, Prop } from 'vue-property-decorator'
import { AbocardActivationData } from '~base/common/types'
import PaywallLinks from '~components/paywall/PaywallLinks.vue'

@Component({
  components: {
    PaywallLinks,
  },
})
export default class AbocardActivation extends Vue {
  @Prop({ type: Object, required: true })
  readonly activationObj!: AbocardActivationData

  private password: string | null = ''
  private email: string | null = ''
  private error: Error | null = null

  get loading() {
    return this.$store.getters['paywall/fetchingData']
  }

  async performActivation() {
    this.error = null
    try {
      await this.$store.dispatch(`paywall/activateAbocard`, {
        email: this.email,
        password: this.password,
        confirmation: this.password,
        card_cusno: this.activationObj.custno,
        card_lastname: this.activationObj.lastname,
      })
      this.$store.dispatch(`paywall/hidePaywall`)
      this.$store.dispatch('paywall/executeExperience')
    } catch (err) {
      this.error = err
    }
  }
}
