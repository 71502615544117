<template>
  <div class="error-page">
    <div v-if="error.statusCode === 404" class="error-container error-404">
      <h1 v-html="$t('error.404.title')" />
      <p>{{ $t('error.404.text') }}</p>
    </div>
    <div
      v-else-if="error.statusCode === 200 && error.message === 'offline'"
      class="error-container error-offline"
    >
      <svg
        fill="none"
        class="mr-2 h-20 w-20"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.28 14.22l.74.74c-.44.21-.84.5-1.2.86l-.02.01-.01.01a.5.5 0 11-.7-.7l.01-.01.01-.02a5.5 5.5 0 011.17-.9zm-6.8-4.76c.32-.35.67-.67 1.04-.97l.72.7c-.38.3-.74.63-1.08.96l-.03.03-.02.03a.5.5 0 11-.7-.7l.03-.02.03-.03zm8.04-3.88c3.93-.42 8 .87 11.02 3.88l.01.02.01.01a.5.5 0 01.03.73.5.5 0 01-.73-.03l-.01-.01-.02-.01a12.47 12.47 0 00-9.37-3.65l-.94-.94zM5.3 12.27c.34-.34.7-.66 1.09-.94l.71.72c-.38.27-.75.58-1.1.92a.5.5 0 01-.7-.7zm12.01.1l-2.43-2.43a9.46 9.46 0 013.82 2.33.5.5 0 01-.7.7 8.56 8.56 0 00-.69-.6zM12 20a1 1 0 110-2 1 1 0 010 2zm7.62.32L3.68 4.38a.5.5 0 01.7-.7l15.94 15.94a.5.5 0 01-.7.7z"
          fill="silver"
          stroke="#000"
        />
      </svg>

      <div>
        <p>{{ $t('error.offline') }}</p>
      </div>
    </div>
    <div v-else class="error-container error-other">
      <h1 v-html="$t('error.generic.title')" />
      <p>{{ $t('error.generic.text') }}</p>
    </div>

    <nuxt-link class="error__back-to-homepage" to="/">{{
      $t('error.backtohomepage')
    }}</nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>
@import '~base/layouts/error.css';
</style>
