function isGaa(query, req) {
  if (!query.gaa_at || !query.gaa_n || !query.gaa_sig || !query.gaa_ts) {
    return false
  }

  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'local'
  ) {
    if (parseInt(query.gaa_ts, 16) < Date.now() / 1000) {
      console.error(
        'SwG Entitlements: The "gaa_ts" URL param should ' +
          'contain a hex string timestamp which points to the future.'
      )
      return false
    }

    // validate referrer
    if (!req.headers.referer) {
      return false
    }

    const GOOGLE_DOMAIN_RE = /(^|\.)google\.(com?|[a-z]{2}|com?\.[a-z]{2}|cat)$/
    const refererUrl = new URL(req.headers.referer)
    if (
      refererUrl.protocol !== 'https:' ||
      !GOOGLE_DOMAIN_RE.test(refererUrl.host)
    ) {
      return false
    }
  }
  return true
}

export default function ({ query, store, req }) {
  if (process.server && isGaa(query, req)) {
    console.log(`📰 this is a valid GAA access`)
    store.commit('google-showcase/SET_FROM_GOOGLE_SHOWCASE')
  }
}
