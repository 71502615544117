<template>
  <header
    class="fixed top-0 z-top w-full border-b border-black-800 bg-white py-2"
    role="banner"
  >
    <div
      class="container mx-auto flex w-full items-center justify-between px-2 md:px-4 lg:px-6"
    >
      <Logo class="z-30 mr-2 h-8 w-8" />
    </div>
  </header>
</template>

<script>
import Logo from '~components/header/Logo.vue'

export default {
  components: {
    Logo,
  },
}
</script>
