<template>
  <BaseWeatherWidgetItem
    :style="{ 'background-color': temperatureColor }"
    v-bind="$props"
  />
</template>

<script>
import BaseWeatherWidgetItem from '../../../base/components/header/WeatherWidgetItem.vue'
import { weatherMixin } from '~base/mixins/weatherMixin.js'
export default {
  components: {
    BaseWeatherWidgetItem,
  },
  mixins: [weatherMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
