





























import { Component, Prop, Vue } from 'vue-property-decorator'

enum PaywallLinkTypes {
  REGISTRATION = 'registration',
  LOGIN = 'login',
  FORGOTPASSWORD = 'forgotpassword',
  ACTIVATION = 'activation',
}

@Component
export default class PaywallLinks extends Vue {
  @Prop({ type: String, default: PaywallLinkTypes.REGISTRATION })
  readonly for!: PaywallLinkTypes

  get isRegistration() {
    return PaywallLinkTypes.REGISTRATION === this.for
  }

  get isForgotPassword() {
    return PaywallLinkTypes.FORGOTPASSWORD === this.for
  }

  get isLogin() {
    return PaywallLinkTypes.LOGIN === this.for
  }

  get isActivation() {
    return PaywallLinkTypes.ACTIVATION === this.for
  }
}
