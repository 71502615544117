export const state = () => ({
  showing: false,
  invisibleShowing: false,
  slotClass: undefined,
  modalType: 'regular',
  canBeClosed: true,
  name: undefined,
  closeCallback: undefined,
})

export const mutations = {
  SET_MODAL_STATE(currentState, shouldShow) {
    currentState.showing = shouldShow
  },
  SET_MODAL_TYPE(currentState, modalType) {
    currentState.modalType = modalType || 'regular'
  },
  SET_SLOT_CLASS(currentState, slotClass) {
    currentState.slotClass = slotClass
  },
  CAN_BE_CLOSED(currentState, canBeClosed) {
    if (canBeClosed === undefined) canBeClosed = false
    currentState.canBeClosed = canBeClosed
  },
  SET_NAME(currentState, name) {
    currentState.name = name
  },
  SET_CLOSE_CALLBACK(currentState, closeCallback) {
    currentState.closeCallback = closeCallback
  },
}

export const actions = {
  setModalType({ commit }, type = 'regular') {
    commit('SET_MODAL_TYPE', type)
  },
  showModal({ commit, dispatch }, data) {
    const { slotClass, modalType, showCloseButton, name, closeCallback } =
      data || {}

    dispatch('setModalType', modalType)
    commit('SET_NAME', name || 'modal')
    commit('SET_SLOT_CLASS', slotClass)
    commit('CAN_BE_CLOSED', showCloseButton)
    commit('SET_CLOSE_CALLBACK', closeCallback)
    commit('SET_MODAL_STATE', true)
    if (window.tp && tp.offer) tp.offer.close()
  },
  hideModal({ commit, getters }) {
    commit('SET_MODAL_STATE', false)
    if (document) {
      document.body.classList.remove('overflow-hidden')
    }
    const closeCallback = getters.closeCallback
    if (closeCallback) {
      closeCallback()
    }
  },
  toggleModal({ getters, dispatch }, data) {
    const showing = getters.isModalShowing
    if (showing) {
      dispatch('hideModal')
    } else {
      dispatch('showModal', data)
    }
  },
}

export const getters = {
  isModalShowing: ({ showing }) => showing,
  slotClass: ({ slotClass }) => slotClass || 'md:max-w-sm',
  modalType: ({ modalType }) => modalType,
  canBeClosed: ({ canBeClosed }) => canBeClosed,
  getName: ({ name }) => name,
  closeCallback: ({ closeCallback }) => closeCallback,
}
