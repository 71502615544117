

















































import { Component, Vue } from 'vue-property-decorator'
import FooterEntry from '~components/footer/FooterEntry.vue'
import { Menu, MenuItem } from '~base/common/types'
import { flatListToHierarchical } from '~plugins/utils'

@Component<Footer>({
  components: {
    FooterEntry,
  },
  apollo: {
    menu: {
      query: require('~base/graphql/queries/GetMenuByName.gql'),
      variables: {
        id: 'Footer',
      },
      result({ data }) {
        if (!data || !data.menu) return
        this.menuItems = flatListToHierarchical(data.menu.menuItems.nodes)
      },
    },
  },
})
export default class Footer extends Vue {
  private menu: Menu = { menuItems: { nodes: [] } }
  private menuItems: MenuItem[] = []

  get categoryClasses() {
    const currentPost = this.$store.getters['news/currentPost']
    if (currentPost) {
      return currentPost.categoryClasses
    }
    return ''
  }
}
