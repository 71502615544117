window.$OneSignal = window.OneSignal = window.OneSignal || [];

OneSignal.push(['init', {
  "appId": "32cbe6bd-37e6-4af4-9d68-effbb25cece4",
  "safari_web_id": "web.onesignal.auto.0818a4e7-118f-4fc1-b0e2-07892e811a2a",
  "allowLocalhostAsSecureOrigin": true,
  "welcomeNotification": {
    "disable": true
  }
}]);

export default function (ctx, inject) {
  inject('OneSignal', OneSignal)
}
