import { isBefore, parseISO } from 'date-fns'
import { GetterTree } from 'vuex'
import { getters as baseGetters, RootState } from '../../base/store/frontpage'
export { state, mutations, actions } from '../../base/store/frontpage'

const expired = (timestamp: string) => {
  return isBefore(parseISO(timestamp), new Date())
}

export const getters: GetterTree<RootState, RootState> = {
  ...baseGetters,
  showCountdownComponent: ({ settings }) =>
    settings.showCountdownComponent &&
    (settings.countdownExpires === null || !expired(settings.countdownExpires)),
}
