import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _756d6c8b = () => interopDefault(import('../themes/vn/pages/gemeinde/index.vue' /* webpackChunkName: "" */))
const _711683bd = () => interopDefault(import('../themes/vn/pages/apa-liveticker.vue' /* webpackChunkName: "pages/apa-liveticker" */))
const _7601f47e = () => interopDefault(import('../themes/vn/pages/energie-monitor.vue' /* webpackChunkName: "pages/energie-monitor" */))
const _2c2066a8 = () => interopDefault(import('../themes/base/pages/ressort/_category/index.vue' /* webpackChunkName: "" */))
const _409d50a8 = () => interopDefault(import('../themes/vn/pages/inhaus.vue' /* webpackChunkName: "pages/inhaus" */))
const _be26912a = () => interopDefault(import('../themes/vn/pages/kreuzwortraetsel.vue' /* webpackChunkName: "pages/kreuzwortraetsel" */))
const _bfdd6f5e = () => interopDefault(import('../themes/vn/pages/meinekueche.vue' /* webpackChunkName: "pages/meinekueche" */))
const _0fec50be = () => interopDefault(import('../themes/vn/pages/mitmachen.vue' /* webpackChunkName: "pages/mitmachen" */))
const _554257a8 = () => interopDefault(import('../themes/vn/pages/newsletter/index.vue' /* webpackChunkName: "pages/newsletter/index" */))
const _066f99c0 = () => interopDefault(import('../themes/base/pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _6c0598de = () => interopDefault(import('../themes/base/pages/suche/index.vue' /* webpackChunkName: "pages/suche/index" */))
const _0d7907c3 = () => interopDefault(import('../themes/vn/pages/sudoku.vue' /* webpackChunkName: "pages/sudoku" */))
const _30a35ea8 = () => interopDefault(import('../themes/base/pages/tipp.vue' /* webpackChunkName: "pages/tipp" */))
const _5714378f = () => interopDefault(import('../themes/base/pages/validate-password.vue' /* webpackChunkName: "pages/validate-password" */))
const _746cd2ce = () => interopDefault(import('../themes/base/pages/admin/debug-vars.vue' /* webpackChunkName: "pages/admin/debug-vars" */))
const _2ed0a99a = () => interopDefault(import('../themes/base/pages/admin/mitmachen/index.vue' /* webpackChunkName: "pages/admin/mitmachen/index" */))
const _4356eea5 = () => interopDefault(import('../themes/base/pages/admin/preview.vue' /* webpackChunkName: "pages/admin/preview" */))
const _e651d274 = () => interopDefault(import('../themes/base/pages/ads/300x250.vue' /* webpackChunkName: "pages/ads/300x250" */))
const _57e7c8af = () => interopDefault(import('../themes/base/pages/ads/300x600.vue' /* webpackChunkName: "pages/ads/300x600" */))
const _26aa0bdc = () => interopDefault(import('../themes/base/pages/ads/testads.vue' /* webpackChunkName: "pages/ads/testads" */))
const _93a016ce = () => interopDefault(import('../themes/vn/pages/app/kreuzwortraetsel.vue' /* webpackChunkName: "pages/app/kreuzwortraetsel" */))
const _0e8516d0 = () => interopDefault(import('../themes/base/pages/app/login.vue' /* webpackChunkName: "pages/app/login" */))
const _60eb0893 = () => interopDefault(import('../themes/vn/pages/app/newsletter.vue' /* webpackChunkName: "pages/app/newsletter" */))
const _00b2df49 = () => interopDefault(import('../themes/vn/pages/app/podcasts.vue' /* webpackChunkName: "pages/app/podcasts" */))
const _0d67703d = () => interopDefault(import('../themes/vn/pages/app/ServiceButton.vue' /* webpackChunkName: "pages/app/ServiceButton" */))
const _8e7f731e = () => interopDefault(import('../themes/vn/pages/app/sudoku.vue' /* webpackChunkName: "pages/app/sudoku" */))
const _38f2b99c = () => interopDefault(import('../themes/vn/pages/app/vorteile.vue' /* webpackChunkName: "pages/app/vorteile" */))
const _35155567 = () => interopDefault(import('../themes/vn/pages/newsletter/anmeldung.vue' /* webpackChunkName: "pages/newsletter/anmeldung" */))
const _2c64892d = () => interopDefault(import('../themes/base/pages/ressort/all.vue' /* webpackChunkName: "pages/ressort/all" */))
const _49162040 = () => interopDefault(import('../themes/vn/pages/ressort/eliteliga/index.vue' /* webpackChunkName: "pages/ressort/eliteliga/index" */))
const _21c69e56 = () => interopDefault(import('../themes/vn/pages/ressort/regionalliga/index.vue' /* webpackChunkName: "pages/ressort/regionalliga/index" */))
const _44b6893c = () => interopDefault(import('../themes/vn/pages/ressort/vorteilsclub/index.vue' /* webpackChunkName: "pages/ressort/vorteilsclub/index" */))
const _dcc05be8 = () => interopDefault(import('../themes/vn/pages/ressort/eliteliga/mediathek.vue' /* webpackChunkName: "pages/ressort/eliteliga/mediathek" */))
const _3c2d54fc = () => interopDefault(import('../themes/vn/pages/ressort/regionalliga/mediathek.vue' /* webpackChunkName: "pages/ressort/regionalliga/mediathek" */))
const _4e0fd860 = () => interopDefault(import('../themes/vn/pages/ressort/eliteliga/page/_page.vue' /* webpackChunkName: "pages/ressort/eliteliga/page/_page" */))
const _dc4201cc = () => interopDefault(import('../themes/vn/pages/ressort/regionalliga/page/_page.vue' /* webpackChunkName: "pages/ressort/regionalliga/page/_page" */))
const _7a681818 = () => interopDefault(import('../themes/vn/pages/ressort/vorteilsclub/page/_page.vue' /* webpackChunkName: "pages/ressort/vorteilsclub/page/_page" */))
const _76b9ca05 = () => interopDefault(import('../themes/vn/pages/gemeinde/page/_page.vue' /* webpackChunkName: "" */))
const _e5f952f8 = () => interopDefault(import('../themes/base/pages/ressort/_category/page/_page.vue' /* webpackChunkName: "" */))
const _6e554c61 = () => interopDefault(import('../themes/vn/pages/newsletter/abmeldung/_list.vue' /* webpackChunkName: "pages/newsletter/abmeldung/_list" */))
const _fc014cdc = () => interopDefault(import('../themes/base/pages/suche/page/_page.vue' /* webpackChunkName: "pages/suche/page/_page" */))
const _35990dbc = () => interopDefault(import('../themes/base/pages/author/_slug/index.vue' /* webpackChunkName: "pages/author/_slug/index" */))
const _9307a904 = () => interopDefault(import('../themes/vn/pages/liveticker/_ticker_slug.vue' /* webpackChunkName: "pages/liveticker/_ticker_slug" */))
const _0127b2a1 = () => interopDefault(import('../themes/base/pages/page/_page.vue' /* webpackChunkName: "pages/page/_page" */))
const _293e73ab = () => interopDefault(import('../themes/vn/pages/promo/_cat_or_page_slug.vue' /* webpackChunkName: "pages/promo/_cat_or_page_slug" */))
const _c02cb29e = () => interopDefault(import('../themes/base/pages/tags/_tag/index.vue' /* webpackChunkName: "pages/tags/_tag/index" */))
const _29483f74 = () => interopDefault(import('../themes/base/pages/author/_slug/page/_page.vue' /* webpackChunkName: "pages/author/_slug/page/_page" */))
const _98ac10c2 = () => interopDefault(import('../themes/base/pages/tags/_tag/page/_page.vue' /* webpackChunkName: "pages/tags/_tag/page/_page" */))
const _0d062ab2 = () => interopDefault(import('../themes/base/pages/author/_slug/_id/index.vue' /* webpackChunkName: "pages/author/_slug/_id/index" */))
const _4cef3de5 = () => interopDefault(import('../themes/base/pages/newsticker/_post_slug/_id.vue' /* webpackChunkName: "pages/newsticker/_post_slug/_id" */))
const _5670bd69 = () => interopDefault(import('../themes/base/pages/author/_slug/_id/page/_page.vue' /* webpackChunkName: "pages/author/_slug/_id/page/_page" */))
const _029f926f = () => interopDefault(import('../themes/base/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e2b68372 = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/index.vue' /* webpackChunkName: "pages/_cat_or_page_slug/index" */))
const _9c748100 = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/_year/_month/_day/_post_slug/index.vue' /* webpackChunkName: "pages/_cat_or_page_slug/_year/_month/_day/_post_slug/index" */))
const _a7f2d61c = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/_year/_month/_day/_post_slug/amp.vue' /* webpackChunkName: "pages/_cat_or_page_slug/_year/_month/_day/_post_slug/amp" */))
const _4af1850a = () => interopDefault(import('../themes/base/pages/_cat_or_page_slug/_year/_month/_day/_post_slug/sharing.vue' /* webpackChunkName: "pages/_cat_or_page_slug/_year/_month/_day/_post_slug/sharing" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alberschwende",
    component: _756d6c8b,
    props: {"locality":["alberschwende"],"nicename":"Alberschwende"},
    name: "alberschwende"
  }, {
    path: "/altach",
    component: _756d6c8b,
    props: {"locality":["altach"],"nicename":"Altach"},
    name: "altach"
  }, {
    path: "/andelsbuch",
    component: _756d6c8b,
    props: {"locality":["andelsbuch"],"nicename":"Andelsbuch"},
    name: "andelsbuch"
  }, {
    path: "/apa-liveticker",
    component: _711683bd,
    name: "apa-liveticker"
  }, {
    path: "/au",
    component: _756d6c8b,
    props: {"locality":["au"],"nicename":"Au"},
    name: "au"
  }, {
    path: "/bartholomaeberg",
    component: _756d6c8b,
    props: {"locality":["bartholomaeberg"],"nicename":"Bartholomäberg"},
    name: "bartholomaeberg"
  }, {
    path: "/bezau",
    component: _756d6c8b,
    props: {"locality":["bezau"],"nicename":"Bezau"},
    name: "bezau"
  }, {
    path: "/bildstein",
    component: _756d6c8b,
    props: {"locality":["bildstein"],"nicename":"Bildstein"},
    name: "bildstein"
  }, {
    path: "/bizau",
    component: _756d6c8b,
    props: {"locality":["bizau"],"nicename":"Bizau"},
    name: "bizau"
  }, {
    path: "/blons",
    component: _756d6c8b,
    props: {"locality":["blons"],"nicename":"Blons"},
    name: "blons"
  }, {
    path: "/bludenz",
    component: _756d6c8b,
    props: {"locality":["bludenz"],"nicename":"Bludenz"},
    name: "bludenz"
  }, {
    path: "/bludesch",
    component: _756d6c8b,
    props: {"locality":["bludesch"],"nicename":"Bludesch"},
    name: "bludesch"
  }, {
    path: "/brand",
    component: _756d6c8b,
    props: {"locality":["brand"],"nicename":"Brand"},
    name: "brand"
  }, {
    path: "/bregenz",
    component: _756d6c8b,
    props: {"locality":["bregenz"],"nicename":"Bregenz"},
    name: "bregenz"
  }, {
    path: "/buch",
    component: _756d6c8b,
    props: {"locality":["buch"],"nicename":"Buch"},
    name: "buch"
  }, {
    path: "/buers",
    component: _756d6c8b,
    props: {"locality":["buers"],"nicename":"Bürs"},
    name: "buers"
  }, {
    path: "/buerserberg",
    component: _756d6c8b,
    props: {"locality":["buerserberg"],"nicename":"Bürserberg"},
    name: "buerserberg"
  }, {
    path: "/dalaas",
    component: _756d6c8b,
    props: {"locality":["dalaas"],"nicename":"Dalaas"},
    name: "dalaas"
  }, {
    path: "/damuels",
    component: _756d6c8b,
    props: {"locality":["damuels"],"nicename":"Damüls"},
    name: "damuels"
  }, {
    path: "/doren",
    component: _756d6c8b,
    props: {"locality":["doren"],"nicename":"Doren"},
    name: "doren"
  }, {
    path: "/dornbirn",
    component: _756d6c8b,
    props: {"locality":["dornbirn"],"nicename":"Dornbirn"},
    name: "dornbirn"
  }, {
    path: "/duens",
    component: _756d6c8b,
    props: {"locality":["duens"],"nicename":"Düns"},
    name: "duens"
  }, {
    path: "/duenserberg",
    component: _756d6c8b,
    props: {"locality":["duenserberg"],"nicename":"Dünserberg"},
    name: "duenserberg"
  }, {
    path: "/egg",
    component: _756d6c8b,
    props: {"locality":["egg"],"nicename":"Egg"},
    name: "egg"
  }, {
    path: "/eichenberg",
    component: _756d6c8b,
    props: {"locality":["eichenberg"],"nicename":"Eichenberg"},
    name: "eichenberg"
  }, {
    path: "/energie-monitor",
    component: _7601f47e,
    name: "energie-monitor"
  }, {
    path: "/feldkirch",
    component: _756d6c8b,
    props: {"locality":["feldkirch"],"nicename":"Feldkirch"},
    name: "feldkirch"
  }, {
    path: "/fontanella",
    component: _756d6c8b,
    props: {"locality":["fontanella"],"nicename":"Fontanella"},
    name: "fontanella"
  }, {
    path: "/frastanz",
    component: _756d6c8b,
    props: {"locality":["frastanz"],"nicename":"Frastanz"},
    name: "frastanz"
  }, {
    path: "/fraxern",
    component: _756d6c8b,
    props: {"locality":["fraxern"],"nicename":"Fraxern"},
    name: "fraxern"
  }, {
    path: "/fussach",
    component: _756d6c8b,
    props: {"locality":["fussach"],"nicename":"Fußach"},
    name: "fussach"
  }, {
    path: "/gaissau",
    component: _756d6c8b,
    props: {"locality":["gaissau"],"nicename":"Gaißau"},
    name: "gaissau"
  }, {
    path: "/gaschurn",
    component: _756d6c8b,
    props: {"locality":["gaschurn"],"nicename":"Gaschurn"},
    name: "gaschurn"
  }, {
    path: "/geburten",
    component: _2c2066a8,
    props: {"category":"geburten"},
    name: "geburten"
  }, {
    path: "/gemeinde",
    component: _756d6c8b,
    name: "gemeinde"
  }, {
    path: "/goefis",
    component: _756d6c8b,
    props: {"locality":["goefis"],"nicename":"Göfis"},
    name: "goefis"
  }, {
    path: "/goetzis",
    component: _756d6c8b,
    props: {"locality":["goetzis"],"nicename":"Götzis"},
    name: "goetzis"
  }, {
    path: "/hard",
    component: _756d6c8b,
    props: {"locality":["hard"],"nicename":"Hard"},
    name: "hard"
  }, {
    path: "/heimat",
    component: _756d6c8b,
    props: {"locality":["alberschwende","altach","andelsbuch","au","bartholomaeberg","bezau","bildstein","bizau","blons","bludenz","bludesch","brand","bregenz","buch","buers","buerserberg","dalaas","damuels","doren","dornbirn","duens","duenserberg","egg","eichenberg","feldkirch","fontanella","frastanz","fraxern","fussach","gaissau","gaschurn","goefis","goetzis","hard","hittisau","hoechst","hoerbranz","hohenems","hohenweiler","innerbraz","kennelbach","klaus","kloesterle","koblach","krumbach","langenbeibregenz","langenegg","laterns","lauterach","lech","lingenau","lochau","loruens","ludesch","lustenau","maeder","meiningen","mellau","mittelberg","moeggers","nenzing","nueziders","raggal","rankweil","reuthe","riefensberg","roens","roethis","stanton","stgallenkirch","stgerold","satteins","schlins","schnepfau","schnifis","schoppernau","schroecken","schruns","schwarzach","schwarzenberg","sibratsgfaell","silbertal","sonntag","stallehr","sulz","sulzberg","thueringen","thueringerberg","tschagguns","uebersaxen","vandans","viktorsberg","warth","weiler","wolfurt","zwischenwasser"],"nicename":"Vorarlberg"},
    name: "heimat"
  }, {
    path: "/hittisau",
    component: _756d6c8b,
    props: {"locality":["hittisau"],"nicename":"Hittisau"},
    name: "hittisau"
  }, {
    path: "/hochzeiten",
    component: _2c2066a8,
    props: {"category":"hochzeiten"},
    name: "hochzeiten"
  }, {
    path: "/hoechst",
    component: _756d6c8b,
    props: {"locality":["hoechst"],"nicename":"Höchst"},
    name: "hoechst"
  }, {
    path: "/hoerbranz",
    component: _756d6c8b,
    props: {"locality":["hoerbranz"],"nicename":"Hörbranz"},
    name: "hoerbranz"
  }, {
    path: "/hohenems",
    component: _756d6c8b,
    props: {"locality":["hohenems"],"nicename":"Hohenems"},
    name: "hohenems"
  }, {
    path: "/hohenweiler",
    component: _756d6c8b,
    props: {"locality":["hohenweiler"],"nicename":"Hohenweiler"},
    name: "hohenweiler"
  }, {
    path: "/inhaus",
    component: _409d50a8,
    name: "inhaus"
  }, {
    path: "/innerbraz",
    component: _756d6c8b,
    props: {"locality":["innerbraz"],"nicename":"Innerbraz"},
    name: "innerbraz"
  }, {
    path: "/kennelbach",
    component: _756d6c8b,
    props: {"locality":["kennelbach"],"nicename":"Kennelbach"},
    name: "kennelbach"
  }, {
    path: "/klaus",
    component: _756d6c8b,
    props: {"locality":["klaus"],"nicename":"Klaus"},
    name: "klaus"
  }, {
    path: "/kloesterle",
    component: _756d6c8b,
    props: {"locality":["kloesterle"],"nicename":"Klösterle"},
    name: "kloesterle"
  }, {
    path: "/koblach",
    component: _756d6c8b,
    props: {"locality":["koblach"],"nicename":"Koblach"},
    name: "koblach"
  }, {
    path: "/kreuzwortraetsel",
    component: _be26912a,
    name: "kreuzwortraetsel"
  }, {
    path: "/krumbach",
    component: _756d6c8b,
    props: {"locality":["krumbach"],"nicename":"Krumbach"},
    name: "krumbach"
  }, {
    path: "/langenbeibregenz",
    component: _756d6c8b,
    props: {"locality":["langenbeibregenz"],"nicename":"Langen bei Bregenz"},
    name: "langenbeibregenz"
  }, {
    path: "/langenegg",
    component: _756d6c8b,
    props: {"locality":["langenegg"],"nicename":"Langenegg"},
    name: "langenegg"
  }, {
    path: "/laterns",
    component: _756d6c8b,
    props: {"locality":["laterns"],"nicename":"Laterns"},
    name: "laterns"
  }, {
    path: "/lauterach",
    component: _756d6c8b,
    props: {"locality":["lauterach"],"nicename":"Lauterach"},
    name: "lauterach"
  }, {
    path: "/lech",
    component: _756d6c8b,
    props: {"locality":["lech"],"nicename":"Lech"},
    name: "lech"
  }, {
    path: "/lingenau",
    component: _756d6c8b,
    props: {"locality":["lingenau"],"nicename":"Lingenau"},
    name: "lingenau"
  }, {
    path: "/lochau",
    component: _756d6c8b,
    props: {"locality":["lochau"],"nicename":"Lochau"},
    name: "lochau"
  }, {
    path: "/loruens",
    component: _756d6c8b,
    props: {"locality":["loruens"],"nicename":"Lorüns"},
    name: "loruens"
  }, {
    path: "/ludesch",
    component: _756d6c8b,
    props: {"locality":["ludesch"],"nicename":"Ludesch"},
    name: "ludesch"
  }, {
    path: "/lustenau",
    component: _756d6c8b,
    props: {"locality":["lustenau"],"nicename":"Lustenau"},
    name: "lustenau"
  }, {
    path: "/maeder",
    component: _756d6c8b,
    props: {"locality":["maeder"],"nicename":"Mäder"},
    name: "maeder"
  }, {
    path: "/meinekueche",
    component: _bfdd6f5e,
    name: "meinekueche"
  }, {
    path: "/meiningen",
    component: _756d6c8b,
    props: {"locality":["meiningen"],"nicename":"Meiningen"},
    name: "meiningen"
  }, {
    path: "/mellau",
    component: _756d6c8b,
    props: {"locality":["mellau"],"nicename":"Mellau"},
    name: "mellau"
  }, {
    path: "/mitmachen",
    component: _0fec50be,
    name: "mitmachen"
  }, {
    path: "/mittelberg",
    component: _756d6c8b,
    props: {"locality":["mittelberg"],"nicename":"Mittelberg"},
    name: "mittelberg"
  }, {
    path: "/moeggers",
    component: _756d6c8b,
    props: {"locality":["moeggers"],"nicename":"Möggers"},
    name: "moeggers"
  }, {
    path: "/nenzing",
    component: _756d6c8b,
    props: {"locality":["nenzing"],"nicename":"Nenzing"},
    name: "nenzing"
  }, {
    path: "/newsletter",
    component: _554257a8,
    name: "newsletter"
  }, {
    path: "/nueziders",
    component: _756d6c8b,
    props: {"locality":["nueziders"],"nicename":"Nüziders"},
    name: "nueziders"
  }, {
    path: "/offline",
    component: _066f99c0,
    name: "offline"
  }, {
    path: "/raggal",
    component: _756d6c8b,
    props: {"locality":["raggal"],"nicename":"Raggal"},
    name: "raggal"
  }, {
    path: "/rankweil",
    component: _756d6c8b,
    props: {"locality":["rankweil"],"nicename":"Rankweil"},
    name: "rankweil"
  }, {
    path: "/reuthe",
    component: _756d6c8b,
    props: {"locality":["reuthe"],"nicename":"Reuthe"},
    name: "reuthe"
  }, {
    path: "/riefensberg",
    component: _756d6c8b,
    props: {"locality":["riefensberg"],"nicename":"Riefensberg"},
    name: "riefensberg"
  }, {
    path: "/roens",
    component: _756d6c8b,
    props: {"locality":["roens"],"nicename":"Röns"},
    name: "roens"
  }, {
    path: "/roethis",
    component: _756d6c8b,
    props: {"locality":["roethis"],"nicename":"Röthis"},
    name: "roethis"
  }, {
    path: "/satteins",
    component: _756d6c8b,
    props: {"locality":["satteins"],"nicename":"Satteins"},
    name: "satteins"
  }, {
    path: "/schlins",
    component: _756d6c8b,
    props: {"locality":["schlins"],"nicename":"Schlins"},
    name: "schlins"
  }, {
    path: "/schnepfau",
    component: _756d6c8b,
    props: {"locality":["schnepfau"],"nicename":"Schnepfau"},
    name: "schnepfau"
  }, {
    path: "/schnifis",
    component: _756d6c8b,
    props: {"locality":["schnifis"],"nicename":"Schnifis"},
    name: "schnifis"
  }, {
    path: "/schoppernau",
    component: _756d6c8b,
    props: {"locality":["schoppernau"],"nicename":"Schoppernau"},
    name: "schoppernau"
  }, {
    path: "/schroecken",
    component: _756d6c8b,
    props: {"locality":["schroecken"],"nicename":"Schröcken"},
    name: "schroecken"
  }, {
    path: "/schruns",
    component: _756d6c8b,
    props: {"locality":["schruns"],"nicename":"Schruns"},
    name: "schruns"
  }, {
    path: "/schwarzach",
    component: _756d6c8b,
    props: {"locality":["schwarzach"],"nicename":"Schwarzach"},
    name: "schwarzach"
  }, {
    path: "/schwarzenberg",
    component: _756d6c8b,
    props: {"locality":["schwarzenberg"],"nicename":"Schwarzenberg"},
    name: "schwarzenberg"
  }, {
    path: "/sibratsgfaell",
    component: _756d6c8b,
    props: {"locality":["sibratsgfaell"],"nicename":"Sibratsgfäll"},
    name: "sibratsgfaell"
  }, {
    path: "/silbertal",
    component: _756d6c8b,
    props: {"locality":["silbertal"],"nicename":"Silbertal"},
    name: "silbertal"
  }, {
    path: "/sonntag",
    component: _756d6c8b,
    props: {"locality":["sonntag"],"nicename":"Sonntag"},
    name: "sonntag"
  }, {
    path: "/stallehr",
    component: _756d6c8b,
    props: {"locality":["stallehr"],"nicename":"Stallehr"},
    name: "stallehr"
  }, {
    path: "/stanton",
    component: _756d6c8b,
    props: {"locality":["stanton"],"nicename":"Sankt Anton im Montafon"},
    name: "stanton"
  }, {
    path: "/stgallenkirch",
    component: _756d6c8b,
    props: {"locality":["stgallenkirch"],"nicename":"Sankt Gallenkirch"},
    name: "stgallenkirch"
  }, {
    path: "/stgerold",
    component: _756d6c8b,
    props: {"locality":["stgerold"],"nicename":"Sankt Gerold"},
    name: "stgerold"
  }, {
    path: "/suche",
    component: _6c0598de,
    name: "suche"
  }, {
    path: "/sudoku",
    component: _0d7907c3,
    name: "sudoku"
  }, {
    path: "/sulz",
    component: _756d6c8b,
    props: {"locality":["sulz"],"nicename":"Sulz"},
    name: "sulz"
  }, {
    path: "/sulzberg",
    component: _756d6c8b,
    props: {"locality":["sulzberg"],"nicename":"Sulzberg"},
    name: "sulzberg"
  }, {
    path: "/taufen",
    component: _2c2066a8,
    props: {"category":"taufen"},
    name: "taufen"
  }, {
    path: "/thueringen",
    component: _756d6c8b,
    props: {"locality":["thueringen"],"nicename":"Thüringen"},
    name: "thueringen"
  }, {
    path: "/thueringerberg",
    component: _756d6c8b,
    props: {"locality":["thueringerberg"],"nicename":"Thüringerberg"},
    name: "thueringerberg"
  }, {
    path: "/tipp",
    component: _30a35ea8,
    name: "tipp"
  }, {
    path: "/tschagguns",
    component: _756d6c8b,
    props: {"locality":["tschagguns"],"nicename":"Tschagguns"},
    name: "tschagguns"
  }, {
    path: "/uebersaxen",
    component: _756d6c8b,
    props: {"locality":["uebersaxen"],"nicename":"Übersaxen"},
    name: "uebersaxen"
  }, {
    path: "/validate-password",
    component: _5714378f,
    name: "validate-password"
  }, {
    path: "/vandans",
    component: _756d6c8b,
    props: {"locality":["vandans"],"nicename":"Vandans"},
    name: "vandans"
  }, {
    path: "/viktorsberg",
    component: _756d6c8b,
    props: {"locality":["viktorsberg"],"nicename":"Viktorsberg"},
    name: "viktorsberg"
  }, {
    path: "/warth",
    component: _756d6c8b,
    props: {"locality":["warth"],"nicename":"Warth"},
    name: "warth"
  }, {
    path: "/weiler",
    component: _756d6c8b,
    props: {"locality":["weiler"],"nicename":"Weiler"},
    name: "weiler"
  }, {
    path: "/wolfurt",
    component: _756d6c8b,
    props: {"locality":["wolfurt"],"nicename":"Wolfurt"},
    name: "wolfurt"
  }, {
    path: "/zwischenwasser",
    component: _756d6c8b,
    props: {"locality":["zwischenwasser"],"nicename":"Zwischenwasser"},
    name: "zwischenwasser"
  }, {
    path: "/admin/debug-vars",
    component: _746cd2ce,
    name: "admin-debug-vars"
  }, {
    path: "/admin/mitmachen",
    component: _2ed0a99a,
    name: "admin-mitmachen"
  }, {
    path: "/admin/preview",
    component: _4356eea5,
    name: "admin-preview"
  }, {
    path: "/ads/300x250",
    component: _e651d274,
    name: "ads-300x250"
  }, {
    path: "/ads/300x600",
    component: _57e7c8af,
    name: "ads-300x600"
  }, {
    path: "/ads/testads",
    component: _26aa0bdc,
    name: "ads-testads"
  }, {
    path: "/app/kreuzwortraetsel",
    component: _93a016ce,
    name: "app-kreuzwortraetsel"
  }, {
    path: "/app/login",
    component: _0e8516d0,
    name: "app-login"
  }, {
    path: "/app/newsletter",
    component: _60eb0893,
    name: "app-newsletter"
  }, {
    path: "/app/podcasts",
    component: _00b2df49,
    name: "app-podcasts"
  }, {
    path: "/app/ServiceButton",
    component: _0d67703d,
    name: "app-ServiceButton"
  }, {
    path: "/app/sudoku",
    component: _8e7f731e,
    name: "app-sudoku"
  }, {
    path: "/app/vorteile",
    component: _38f2b99c,
    name: "app-vorteile"
  }, {
    path: "/newsletter/anmeldung",
    component: _35155567,
    name: "newsletter-anmeldung"
  }, {
    path: "/ressort/all",
    component: _2c64892d,
    name: "ressort-all"
  }, {
    path: "/ressort/eliteliga",
    component: _49162040,
    name: "ressort-eliteliga"
  }, {
    path: "/ressort/regionalliga",
    component: _21c69e56,
    name: "ressort-regionalliga"
  }, {
    path: "/ressort/vorteilsclub",
    component: _44b6893c,
    name: "ressort-vorteilsclub"
  }, {
    path: "/ressort/eliteliga/mediathek",
    component: _dcc05be8,
    name: "ressort-eliteliga-mediathek"
  }, {
    path: "/ressort/regionalliga/mediathek",
    component: _3c2d54fc,
    name: "ressort-regionalliga-mediathek"
  }, {
    path: "/ressort/eliteliga/page/:page?",
    component: _4e0fd860,
    name: "ressort-eliteliga-page-page"
  }, {
    path: "/ressort/regionalliga/page/:page?",
    component: _dc4201cc,
    name: "ressort-regionalliga-page-page"
  }, {
    path: "/ressort/vorteilsclub/page/:page?",
    component: _7a681818,
    name: "ressort-vorteilsclub-page-page"
  }, {
    path: "/alberschwende/page/:page?",
    component: _76b9ca05,
    props: {"locality":["alberschwende"],"nicename":"Alberschwende"},
    name: "alberschwende-page-page"
  }, {
    path: "/altach/page/:page?",
    component: _76b9ca05,
    props: {"locality":["altach"],"nicename":"Altach"},
    name: "altach-page-page"
  }, {
    path: "/andelsbuch/page/:page?",
    component: _76b9ca05,
    props: {"locality":["andelsbuch"],"nicename":"Andelsbuch"},
    name: "andelsbuch-page-page"
  }, {
    path: "/au/page/:page?",
    component: _76b9ca05,
    props: {"locality":["au"],"nicename":"Au"},
    name: "au-page-page"
  }, {
    path: "/bartholomaeberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["bartholomaeberg"],"nicename":"Bartholomäberg"},
    name: "bartholomaeberg-page-page"
  }, {
    path: "/bezau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["bezau"],"nicename":"Bezau"},
    name: "bezau-page-page"
  }, {
    path: "/bildstein/page/:page?",
    component: _76b9ca05,
    props: {"locality":["bildstein"],"nicename":"Bildstein"},
    name: "bildstein-page-page"
  }, {
    path: "/bizau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["bizau"],"nicename":"Bizau"},
    name: "bizau-page-page"
  }, {
    path: "/blons/page/:page?",
    component: _76b9ca05,
    props: {"locality":["blons"],"nicename":"Blons"},
    name: "blons-page-page"
  }, {
    path: "/bludenz/page/:page?",
    component: _76b9ca05,
    props: {"locality":["bludenz"],"nicename":"Bludenz"},
    name: "bludenz-page-page"
  }, {
    path: "/bludesch/page/:page?",
    component: _76b9ca05,
    props: {"locality":["bludesch"],"nicename":"Bludesch"},
    name: "bludesch-page-page"
  }, {
    path: "/brand/page/:page?",
    component: _76b9ca05,
    props: {"locality":["brand"],"nicename":"Brand"},
    name: "brand-page-page"
  }, {
    path: "/bregenz/page/:page?",
    component: _76b9ca05,
    props: {"locality":["bregenz"],"nicename":"Bregenz"},
    name: "bregenz-page-page"
  }, {
    path: "/buch/page/:page?",
    component: _76b9ca05,
    props: {"locality":["buch"],"nicename":"Buch"},
    name: "buch-page-page"
  }, {
    path: "/buers/page/:page?",
    component: _76b9ca05,
    props: {"locality":["buers"],"nicename":"Bürs"},
    name: "buers-page-page"
  }, {
    path: "/buerserberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["buerserberg"],"nicename":"Bürserberg"},
    name: "buerserberg-page-page"
  }, {
    path: "/dalaas/page/:page?",
    component: _76b9ca05,
    props: {"locality":["dalaas"],"nicename":"Dalaas"},
    name: "dalaas-page-page"
  }, {
    path: "/damuels/page/:page?",
    component: _76b9ca05,
    props: {"locality":["damuels"],"nicename":"Damüls"},
    name: "damuels-page-page"
  }, {
    path: "/doren/page/:page?",
    component: _76b9ca05,
    props: {"locality":["doren"],"nicename":"Doren"},
    name: "doren-page-page"
  }, {
    path: "/dornbirn/page/:page?",
    component: _76b9ca05,
    props: {"locality":["dornbirn"],"nicename":"Dornbirn"},
    name: "dornbirn-page-page"
  }, {
    path: "/duens/page/:page?",
    component: _76b9ca05,
    props: {"locality":["duens"],"nicename":"Düns"},
    name: "duens-page-page"
  }, {
    path: "/duenserberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["duenserberg"],"nicename":"Dünserberg"},
    name: "duenserberg-page-page"
  }, {
    path: "/egg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["egg"],"nicename":"Egg"},
    name: "egg-page-page"
  }, {
    path: "/eichenberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["eichenberg"],"nicename":"Eichenberg"},
    name: "eichenberg-page-page"
  }, {
    path: "/feldkirch/page/:page?",
    component: _76b9ca05,
    props: {"locality":["feldkirch"],"nicename":"Feldkirch"},
    name: "feldkirch-page-page"
  }, {
    path: "/fontanella/page/:page?",
    component: _76b9ca05,
    props: {"locality":["fontanella"],"nicename":"Fontanella"},
    name: "fontanella-page-page"
  }, {
    path: "/frastanz/page/:page?",
    component: _76b9ca05,
    props: {"locality":["frastanz"],"nicename":"Frastanz"},
    name: "frastanz-page-page"
  }, {
    path: "/fraxern/page/:page?",
    component: _76b9ca05,
    props: {"locality":["fraxern"],"nicename":"Fraxern"},
    name: "fraxern-page-page"
  }, {
    path: "/fussach/page/:page?",
    component: _76b9ca05,
    props: {"locality":["fussach"],"nicename":"Fußach"},
    name: "fussach-page-page"
  }, {
    path: "/gaissau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["gaissau"],"nicename":"Gaißau"},
    name: "gaissau-page-page"
  }, {
    path: "/gaschurn/page/:page?",
    component: _76b9ca05,
    props: {"locality":["gaschurn"],"nicename":"Gaschurn"},
    name: "gaschurn-page-page"
  }, {
    path: "/geburten/page/:page?",
    component: _e5f952f8,
    props: {"category":"geburten"},
    name: "geburten-page-page"
  }, {
    path: "/gemeinde/page/:page?",
    component: _76b9ca05,
    name: "gemeinde-page-page"
  }, {
    path: "/goefis/page/:page?",
    component: _76b9ca05,
    props: {"locality":["goefis"],"nicename":"Göfis"},
    name: "goefis-page-page"
  }, {
    path: "/goetzis/page/:page?",
    component: _76b9ca05,
    props: {"locality":["goetzis"],"nicename":"Götzis"},
    name: "goetzis-page-page"
  }, {
    path: "/hard/page/:page?",
    component: _76b9ca05,
    props: {"locality":["hard"],"nicename":"Hard"},
    name: "hard-page-page"
  }, {
    path: "/heimat/page/:page?",
    component: _76b9ca05,
    props: {"locality":["alberschwende","altach","andelsbuch","au","bartholomaeberg","bezau","bildstein","bizau","blons","bludenz","bludesch","brand","bregenz","buch","buers","buerserberg","dalaas","damuels","doren","dornbirn","duens","duenserberg","egg","eichenberg","feldkirch","fontanella","frastanz","fraxern","fussach","gaissau","gaschurn","goefis","goetzis","hard","hittisau","hoechst","hoerbranz","hohenems","hohenweiler","innerbraz","kennelbach","klaus","kloesterle","koblach","krumbach","langenbeibregenz","langenegg","laterns","lauterach","lech","lingenau","lochau","loruens","ludesch","lustenau","maeder","meiningen","mellau","mittelberg","moeggers","nenzing","nueziders","raggal","rankweil","reuthe","riefensberg","roens","roethis","stanton","stgallenkirch","stgerold","satteins","schlins","schnepfau","schnifis","schoppernau","schroecken","schruns","schwarzach","schwarzenberg","sibratsgfaell","silbertal","sonntag","stallehr","sulz","sulzberg","thueringen","thueringerberg","tschagguns","uebersaxen","vandans","viktorsberg","warth","weiler","wolfurt","zwischenwasser"],"nicename":"Vorarlberg"},
    name: "heimat-page-page"
  }, {
    path: "/hittisau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["hittisau"],"nicename":"Hittisau"},
    name: "hittisau-page-page"
  }, {
    path: "/hochzeiten/page/:page?",
    component: _e5f952f8,
    props: {"category":"hochzeiten"},
    name: "hochzeiten-page-page"
  }, {
    path: "/hoechst/page/:page?",
    component: _76b9ca05,
    props: {"locality":["hoechst"],"nicename":"Höchst"},
    name: "hoechst-page-page"
  }, {
    path: "/hoerbranz/page/:page?",
    component: _76b9ca05,
    props: {"locality":["hoerbranz"],"nicename":"Hörbranz"},
    name: "hoerbranz-page-page"
  }, {
    path: "/hohenems/page/:page?",
    component: _76b9ca05,
    props: {"locality":["hohenems"],"nicename":"Hohenems"},
    name: "hohenems-page-page"
  }, {
    path: "/hohenweiler/page/:page?",
    component: _76b9ca05,
    props: {"locality":["hohenweiler"],"nicename":"Hohenweiler"},
    name: "hohenweiler-page-page"
  }, {
    path: "/innerbraz/page/:page?",
    component: _76b9ca05,
    props: {"locality":["innerbraz"],"nicename":"Innerbraz"},
    name: "innerbraz-page-page"
  }, {
    path: "/kennelbach/page/:page?",
    component: _76b9ca05,
    props: {"locality":["kennelbach"],"nicename":"Kennelbach"},
    name: "kennelbach-page-page"
  }, {
    path: "/klaus/page/:page?",
    component: _76b9ca05,
    props: {"locality":["klaus"],"nicename":"Klaus"},
    name: "klaus-page-page"
  }, {
    path: "/kloesterle/page/:page?",
    component: _76b9ca05,
    props: {"locality":["kloesterle"],"nicename":"Klösterle"},
    name: "kloesterle-page-page"
  }, {
    path: "/koblach/page/:page?",
    component: _76b9ca05,
    props: {"locality":["koblach"],"nicename":"Koblach"},
    name: "koblach-page-page"
  }, {
    path: "/krumbach/page/:page?",
    component: _76b9ca05,
    props: {"locality":["krumbach"],"nicename":"Krumbach"},
    name: "krumbach-page-page"
  }, {
    path: "/langenbeibregenz/page/:page?",
    component: _76b9ca05,
    props: {"locality":["langenbeibregenz"],"nicename":"Langen bei Bregenz"},
    name: "langenbeibregenz-page-page"
  }, {
    path: "/langenegg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["langenegg"],"nicename":"Langenegg"},
    name: "langenegg-page-page"
  }, {
    path: "/laterns/page/:page?",
    component: _76b9ca05,
    props: {"locality":["laterns"],"nicename":"Laterns"},
    name: "laterns-page-page"
  }, {
    path: "/lauterach/page/:page?",
    component: _76b9ca05,
    props: {"locality":["lauterach"],"nicename":"Lauterach"},
    name: "lauterach-page-page"
  }, {
    path: "/lech/page/:page?",
    component: _76b9ca05,
    props: {"locality":["lech"],"nicename":"Lech"},
    name: "lech-page-page"
  }, {
    path: "/lingenau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["lingenau"],"nicename":"Lingenau"},
    name: "lingenau-page-page"
  }, {
    path: "/lochau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["lochau"],"nicename":"Lochau"},
    name: "lochau-page-page"
  }, {
    path: "/loruens/page/:page?",
    component: _76b9ca05,
    props: {"locality":["loruens"],"nicename":"Lorüns"},
    name: "loruens-page-page"
  }, {
    path: "/ludesch/page/:page?",
    component: _76b9ca05,
    props: {"locality":["ludesch"],"nicename":"Ludesch"},
    name: "ludesch-page-page"
  }, {
    path: "/lustenau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["lustenau"],"nicename":"Lustenau"},
    name: "lustenau-page-page"
  }, {
    path: "/maeder/page/:page?",
    component: _76b9ca05,
    props: {"locality":["maeder"],"nicename":"Mäder"},
    name: "maeder-page-page"
  }, {
    path: "/meiningen/page/:page?",
    component: _76b9ca05,
    props: {"locality":["meiningen"],"nicename":"Meiningen"},
    name: "meiningen-page-page"
  }, {
    path: "/mellau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["mellau"],"nicename":"Mellau"},
    name: "mellau-page-page"
  }, {
    path: "/mittelberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["mittelberg"],"nicename":"Mittelberg"},
    name: "mittelberg-page-page"
  }, {
    path: "/moeggers/page/:page?",
    component: _76b9ca05,
    props: {"locality":["moeggers"],"nicename":"Möggers"},
    name: "moeggers-page-page"
  }, {
    path: "/nenzing/page/:page?",
    component: _76b9ca05,
    props: {"locality":["nenzing"],"nicename":"Nenzing"},
    name: "nenzing-page-page"
  }, {
    path: "/newsletter/abmeldung/:list?",
    component: _6e554c61,
    name: "newsletter-abmeldung-list"
  }, {
    path: "/nueziders/page/:page?",
    component: _76b9ca05,
    props: {"locality":["nueziders"],"nicename":"Nüziders"},
    name: "nueziders-page-page"
  }, {
    path: "/raggal/page/:page?",
    component: _76b9ca05,
    props: {"locality":["raggal"],"nicename":"Raggal"},
    name: "raggal-page-page"
  }, {
    path: "/rankweil/page/:page?",
    component: _76b9ca05,
    props: {"locality":["rankweil"],"nicename":"Rankweil"},
    name: "rankweil-page-page"
  }, {
    path: "/reuthe/page/:page?",
    component: _76b9ca05,
    props: {"locality":["reuthe"],"nicename":"Reuthe"},
    name: "reuthe-page-page"
  }, {
    path: "/riefensberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["riefensberg"],"nicename":"Riefensberg"},
    name: "riefensberg-page-page"
  }, {
    path: "/roens/page/:page?",
    component: _76b9ca05,
    props: {"locality":["roens"],"nicename":"Röns"},
    name: "roens-page-page"
  }, {
    path: "/roethis/page/:page?",
    component: _76b9ca05,
    props: {"locality":["roethis"],"nicename":"Röthis"},
    name: "roethis-page-page"
  }, {
    path: "/satteins/page/:page?",
    component: _76b9ca05,
    props: {"locality":["satteins"],"nicename":"Satteins"},
    name: "satteins-page-page"
  }, {
    path: "/schlins/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schlins"],"nicename":"Schlins"},
    name: "schlins-page-page"
  }, {
    path: "/schnepfau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schnepfau"],"nicename":"Schnepfau"},
    name: "schnepfau-page-page"
  }, {
    path: "/schnifis/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schnifis"],"nicename":"Schnifis"},
    name: "schnifis-page-page"
  }, {
    path: "/schoppernau/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schoppernau"],"nicename":"Schoppernau"},
    name: "schoppernau-page-page"
  }, {
    path: "/schroecken/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schroecken"],"nicename":"Schröcken"},
    name: "schroecken-page-page"
  }, {
    path: "/schruns/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schruns"],"nicename":"Schruns"},
    name: "schruns-page-page"
  }, {
    path: "/schwarzach/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schwarzach"],"nicename":"Schwarzach"},
    name: "schwarzach-page-page"
  }, {
    path: "/schwarzenberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["schwarzenberg"],"nicename":"Schwarzenberg"},
    name: "schwarzenberg-page-page"
  }, {
    path: "/sibratsgfaell/page/:page?",
    component: _76b9ca05,
    props: {"locality":["sibratsgfaell"],"nicename":"Sibratsgfäll"},
    name: "sibratsgfaell-page-page"
  }, {
    path: "/silbertal/page/:page?",
    component: _76b9ca05,
    props: {"locality":["silbertal"],"nicename":"Silbertal"},
    name: "silbertal-page-page"
  }, {
    path: "/sonntag/page/:page?",
    component: _76b9ca05,
    props: {"locality":["sonntag"],"nicename":"Sonntag"},
    name: "sonntag-page-page"
  }, {
    path: "/stallehr/page/:page?",
    component: _76b9ca05,
    props: {"locality":["stallehr"],"nicename":"Stallehr"},
    name: "stallehr-page-page"
  }, {
    path: "/stanton/page/:page?",
    component: _76b9ca05,
    props: {"locality":["stanton"],"nicename":"Sankt Anton im Montafon"},
    name: "stanton-page-page"
  }, {
    path: "/stgallenkirch/page/:page?",
    component: _76b9ca05,
    props: {"locality":["stgallenkirch"],"nicename":"Sankt Gallenkirch"},
    name: "stgallenkirch-page-page"
  }, {
    path: "/stgerold/page/:page?",
    component: _76b9ca05,
    props: {"locality":["stgerold"],"nicename":"Sankt Gerold"},
    name: "stgerold-page-page"
  }, {
    path: "/suche/page/:page?",
    component: _fc014cdc,
    name: "suche-page-page"
  }, {
    path: "/sulz/page/:page?",
    component: _76b9ca05,
    props: {"locality":["sulz"],"nicename":"Sulz"},
    name: "sulz-page-page"
  }, {
    path: "/sulzberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["sulzberg"],"nicename":"Sulzberg"},
    name: "sulzberg-page-page"
  }, {
    path: "/taufen/page/:page?",
    component: _e5f952f8,
    props: {"category":"taufen"},
    name: "taufen-page-page"
  }, {
    path: "/thueringen/page/:page?",
    component: _76b9ca05,
    props: {"locality":["thueringen"],"nicename":"Thüringen"},
    name: "thueringen-page-page"
  }, {
    path: "/thueringerberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["thueringerberg"],"nicename":"Thüringerberg"},
    name: "thueringerberg-page-page"
  }, {
    path: "/tschagguns/page/:page?",
    component: _76b9ca05,
    props: {"locality":["tschagguns"],"nicename":"Tschagguns"},
    name: "tschagguns-page-page"
  }, {
    path: "/uebersaxen/page/:page?",
    component: _76b9ca05,
    props: {"locality":["uebersaxen"],"nicename":"Übersaxen"},
    name: "uebersaxen-page-page"
  }, {
    path: "/vandans/page/:page?",
    component: _76b9ca05,
    props: {"locality":["vandans"],"nicename":"Vandans"},
    name: "vandans-page-page"
  }, {
    path: "/viktorsberg/page/:page?",
    component: _76b9ca05,
    props: {"locality":["viktorsberg"],"nicename":"Viktorsberg"},
    name: "viktorsberg-page-page"
  }, {
    path: "/warth/page/:page?",
    component: _76b9ca05,
    props: {"locality":["warth"],"nicename":"Warth"},
    name: "warth-page-page"
  }, {
    path: "/weiler/page/:page?",
    component: _76b9ca05,
    props: {"locality":["weiler"],"nicename":"Weiler"},
    name: "weiler-page-page"
  }, {
    path: "/wolfurt/page/:page?",
    component: _76b9ca05,
    props: {"locality":["wolfurt"],"nicename":"Wolfurt"},
    name: "wolfurt-page-page"
  }, {
    path: "/zwischenwasser/page/:page?",
    component: _76b9ca05,
    props: {"locality":["zwischenwasser"],"nicename":"Zwischenwasser"},
    name: "zwischenwasser-page-page"
  }, {
    path: "/author/:slug",
    component: _35990dbc,
    name: "author-slug"
  }, {
    path: "/liveticker/:ticker_slug?",
    component: _9307a904,
    name: "liveticker-ticker_slug"
  }, {
    path: "/page/:page?",
    component: _0127b2a1,
    name: "page-page"
  }, {
    path: "/promo/:cat_or_page_slug?",
    component: _293e73ab,
    name: "promo-cat_or_page_slug"
  }, {
    path: "/ressort/:category",
    component: _2c2066a8,
    name: "ressort-category"
  }, {
    path: "/tags/:tag",
    component: _c02cb29e,
    name: "tags-tag"
  }, {
    path: "/author/:slug?/page/:page?",
    component: _29483f74,
    name: "author-slug-page-page"
  }, {
    path: "/ressort/:category?/page/:page?",
    component: _e5f952f8,
    name: "ressort-category-page-page"
  }, {
    path: "/tags/:tag?/page/:page?",
    component: _98ac10c2,
    name: "tags-tag-page-page"
  }, {
    path: "/author/:slug?/:id",
    component: _0d062ab2,
    name: "author-slug-id"
  }, {
    path: "/newsticker/:post_slug?/:id?",
    component: _4cef3de5,
    name: "newsticker-post_slug-id"
  }, {
    path: "/author/:slug?/:id/page/:page?",
    component: _5670bd69,
    name: "author-slug-id-page-page"
  }, {
    path: "/",
    component: _029f926f,
    name: "index"
  }, {
    path: "/:cat_or_page_slug",
    component: _e2b68372,
    name: "cat_or_page_slug"
  }, {
    path: "/:cat_or_page_slug/:year/:month?/:day?/:post_slug",
    component: _9c748100,
    name: "cat_or_page_slug-year-month-day-post_slug"
  }, {
    path: "/:cat_or_page_slug/:year/:month?/:day?/:post_slug?/amp",
    component: _a7f2d61c,
    name: "cat_or_page_slug-year-month-day-post_slug-amp"
  }, {
    path: "/:cat_or_page_slug/:year/:month?/:day?/:post_slug?/sharing",
    component: _4af1850a,
    name: "cat_or_page_slug-year-month-day-post_slug-sharing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
