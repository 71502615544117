<template>
  <li class="group">
    <a
      v-if="isExternalLink"
      :href="item.url"
      :target="item.target"
      :rel="item.target ? 'noreferrer' : ''"
      :title="item.title"
      :class="cssClasses"
      class="menu-item"
      @click="handleExternal(item.url)"
    >
      {{ item.label }}
    </a>
    <a
      v-else
      :href="item.url"
      :title="item.title"
      :class="cssClasses"
      class="menu-item"
      :data-testid="testId"
      @click.prevent="navigate(item)"
    >
      {{ item.label }}
      <svg
        v-if="item.childItems && item.childItems.length"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 12.95l-.35-.35L5.05 8l.7-.7 3.9 3.88.35.36.35-.36 3.9-3.89.7.71L10 12.95z"
        />
      </svg>
    </a>
    <ul
      v-if="item.childItems && item.childItems.length"
      class="submenu group-hover:block"
    >
      <menu-item
        v-for="(submenuItem, index) in item.childItems"
        :key="submenuItem.id || index"
        :item="submenuItem"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'MenuItem',
  components: {
    MenuItem: this,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isExternalLink() {
      return this.item.url && this.item.url.startsWith('http')
    },
    testId() {
      return this.item.url
        ? `menu-item-${this.item.url.split('/').pop()}`
        : undefined
    },
    cssClasses() {
      const classes = this.item.cssClasses
      const selectedClass =
        this.$route && this.$route.path === this.item.url
          ? 'menu-item--selected'
          : ''
      return `${classes.join(' ')} ${selectedClass}`
    },
  },
  methods: {
    handleExternal(url) {
      this.$store.dispatch('menu/closeMenu')
      if (url === 'https://epaper.vn.at') {
        this.$store.dispatch('tracking/trackGeneric', {
          category: 'epaper',
          action: 'click',
        })
      }
    },
    navigate(item) {
      setTimeout(() => {
        this.$store.dispatch('menu/closeMenu')
      }, 300)
      if (item.url) {
        this.$router.push(item.url)
        const ressort = item.url.match(/^\/ressort\/(.*)\/?$/)
        if (ressort !== null) {
          this.$store.dispatch('tracking/trackGeneric', {
            category: 'ressorts',
            action: 'click',
            label: ressort[1],
          })
        }
      } else if (item.click && typeof item.click === 'function') {
        item.click()
      }
    },
  },
}
</script>

<style>
.menu-item {
  @apply cursor-pointer;
}
</style>
