<template>
  <BaseError :error="error" />
</template>

<script>
import BaseError from '~~/themes/base/layouts/error.vue'
export default {
  components: {
    BaseError,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>
