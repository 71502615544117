import merge from 'lodash.merge'
import lang from '../../base/lang/de'

const vn = {
  menu: {
    register: `Mehr zu V+`,
  },
  epaper: {
    description: "Hier geht's zum E-Paper der Vorarlberger Nachrichten",
  },
  obituary: {
    title: 'Todesanzeigen',
    for: 'Todesanzeige ansehen für',
    showmore: 'Mehr anzeigen',
    visitsite: 'Mehr Anzeigen auf dem Todesanzeigen Portal anzeigen',
  },
  footer: {
    copyright: ' · Vorarlberger Nachrichten · Russmedia Verlag',
  },
  seo: {
    title: {
      category: {
        default: 'Aktuelle Nachrichten auf VN.at',
        eliteliga:
          'Eliteliga Vorarlberg: Ergebnisse, Tabelle und Spielberichte - VN.at',
      },
      tags: {
        default: 'Aktuelle Nachrichten auf VN.at',
        webinar: 'Digitaler VN-Stammtisch zum Nachsehen - VN.at',
      },
      search: 'Aktuelle Nachrichten auf VN.at',
      all: 'Alle Kategorien auf VN.at',
      locality: 'Aktuelle lokale Nachrichten auf VN.at',
      author: 'Artikel von {0} auf VN.at',
      mitmachen: 'VN Gewinnspiel - Mitmachen und Gewinnen',
      tipp: 'Senden Sie einen Tipp an die Redaktion',
    },
    description: {
      category: {
        default:
          'Alle aktuellen News aus dem Ressort {0} schnell und übersichtlich von den Vorarlberger Nachrichten. Hier verpassen Sie keine {0}-Nachrichten.',
        eliteliga:
          'Alles zur VN.at Eliteliga in Vorarlberg: Übersichtliche Ergebnisse, der Spielplan, die Tabelle sowie detaillierte Spielberichte und die Torschützenliste. Hier verpassen Sie keine Nachricht aus der Fußball Eliteliga.',
      },
      tags: {
        default:
          'Alle aktuellen News zu #{0} schnell und übersichtlich von den Vorarlberger Nachrichten. Hier verpassen Sie keine Nachrichten über #{0}.',
        webinar:
          'Alle digitalen VN-Stammtische zum Nachsehen. Schnell und übersichtlich. Hier verpassen Sie keine digitalen VN-Stammtische.',
      },
      search:
        'Alle aktuellen News zu „{0}“ schnell und übersichtlich von den Vorarlberger Nachrichten. Hier verpassen Sie keine Nachrichten über „{0}“.',
      locality:
        'Alle lokalen News aus {0} schnell und übersichtlich von den Vorarlberger Nachrichten. Hier verpassen Sie keine Nachrichten aus {0}.',
      author:
        'Alle Artikel von {0} schnell und übersichtlich auf VN.at. Hier verpassen Sie keine Artikel von {0}.',
      mitmachen: `Immer wieder tolle Gewinnspiele mit den VN.`,
      tipp: 'Haben Sie einen Hinweis für uns? Wissen Sie etwas, was passiert ist, worüber wir schreiben sollten? Gibt es etwas, das wir uns genauer ansehen sollten?',
    },
  },
  app: {
    onboarding: {
      calltoaction: 'Jetzt freischalten und weiterlesen.',
      title: 'Ihre Vorarlberger Nachrichten digital',
      features:
        '<li>Lokaler Journalismus mit Engagement, unabhängig von der Tageszeit</li><li>Reportagen, Hintergründe, Kommentare und Schwerpunktberichterstattungen</li><li>Animierte Grafiken, Bilderserien, Videos und Podcasts</li>',
      loginbuttontext: 'Login als VN-Kunde',
      registerbuttontext: 'Neu registrieren',
      pricinginformation: '1 Monat gratis, danach ab 31,90 € pro Monat',
    },
    upsell: {
      info: `Sichern Sie sich als Leser der gedruckten Zeitung unser Vorteilsangebot.
      Lesen Sie alle Neuigkeiten auch auf unserem
      <b>Premium-Newsportal VN.at</b> und greifen Sie auf das
      <b>E-Paper</b> und das gesamte <b>Nachrichtenarchiv</b> zu.`,
    },
    offer: {
      title: 'Angebot sichern:',
    },
  },
  'author-page': {
    contact: 'Kontakt',
    'mail-subject': 'Kontakt über VN.at',
    'all-content': 'Alle Artikel von {name}',
    'all-content-count': '{count} Artikel',
  },
  mitmachen: {
    title: 'Mitmachen und gewinnen',
    catchphrase: 'Immer wieder tolle Gewinnspiele mit den VN',
    subscriber: 'Ich bin VN Abonnent?',
    abono: `VN Abonummer`,
    orderonlinetest: `Ja, ich bestelle die <strong>digitalen VN</strong> für vier Wochen gratis – inklusive Zugang zum E-Paper, zum Nachrichtenportal VN.at und zu allen V+ Artikeln auf VOL.AT – Das Abo endet automatisch.`,
    orderprinttest: `Ja, ich bestelle <strong>VN-Premium</strong> für zwei Wochen kostenlos – inklusive der gedruckten VN, Zugang zum E-Paper, zum Nachrichtenportal VN.at und zu allen V+ Artikeln auf VOL.AT – Das Abo endet automatisch.`,
    newsletter: `VN-Morgennewsletter abonnieren`,
    success: `Herzlichen Dank für Ihre Teilnahme am VN.at-Gewinnspiel und viel Glück!`,
    offers: `Ja, ich möchte von Vorteilsangeboten profitieren und bin damit einverstanden, dass die Russmedia Digital GmbH meine Daten (= Name, Adresse, E-Mail-Adresse und Telefonnummer) zwecks Kontaktaufnahme per Brief, Telefon, Messenger Dienst oder E-Mail zur Information über Produkte und aktuelle Angebote der Russmedia-Gruppe verarbeitet sowie zu diesem Zweck an <a href="https://www.vn.at/russmedia-gruppe" target="_blank">Unternehmen der Russmedia-Gruppe</a> weitergibt. Diese Einwilligung kann ich jederzeit widerrufen.
    <br>Weitere Informationen unter: <a href="https://vn.at/datenschutz" target="_blank">Datenschutzinformationen</a>`,
    privacy: ` Ich akzeptiere die
    <a href="https://www.russmedia.com/russmedia-gruppe-teilnahmebedingungen/" target="_blank">Teilnahmebedingungen</a> und <a href="https://www.vn.at/agb" target="_blank">AGBs</a> der Russmedia Verlag GmbH.`,
    sub: {
      question: `Möchten Sie die digitalen Inhalte der VN (E-Paper und VN.at) inklusive Zugang zu allen V+ Premiuminhalten auf VOL.AT kostenlos für 4 Wochen testen? *`,
      order: `Ja, ich möchte den VN Digitalzugang für 4 Wochen kostenlos testen.`,
      noorder: `Nein, ich habe derzeit keinen Bedarf.`,
      info: `* Das Angebot gilt nur für Abonnenten der gedruckten VN. Der kostenlose Probezugang kann nicht auf ein bestehendes Digitalabonnement angerechnet werden.`,
    },
  },
  aboshopWidget: {
    buttonText: 'Zum VN-Aboshop',
  },
  search: {
    noEntries: 'Es wurden leider keine Einträge gefunden.',
  },
}

export default merge(lang, vn)
