




import { Vue, Component } from 'vue-property-decorator'

@Component({
  head() {
    return {
      script: [
        {
          innerHTML: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.$config.gtmTag}');
      `,
        },
      ],
      noscript: [
        {
          innerHTML: `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${this.$config.gtmTag}" height="0" width="0"
      style="display:none;visibility:hidden"></iframe>
      `,
        },
      ],
      __dangerouslyDisableSanitizers: ['script', 'noscript'],
    }
  },
})
export default class App extends Vue {}
