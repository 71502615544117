import { toDate, parseISO, format } from 'date-fns'
import { BlockAttribute, BlockType } from '../common/types'
import { entityDecode } from '../../../utils/entity-decode.js'
import {
  Post,
  EnhancedPost,
  Term,
  Block,
  Page,
  PostsInLayout,
  Layout,
  MenuItem,
  MenuEntry,
} from '~base/common/types'

export const undomainify = (url: string): string =>
  url.replace(/^.*\/\/[^/]+/, '')

export const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const enhance = (
  p: Post | EnhancedPost,
  settings: any
): EnhancedPost | undefined => {
  if (!p) return undefined
  const workingPost: EnhancedPost = {
    enhanced: false,
    type: 'post',
    hasPicture: false,
    renderComponent: 'Regular',
    categoryClasses: '',
    link: '',
    permalink: '',
    ...p,
  }
  if (!workingPost.enhanced) {
    workingPost.hasPicture = hasPicture(workingPost)
    workingPost.renderComponent = getRenderComponent(workingPost)
    workingPost.relevantCategories = workingPost.categories?.nodes?.filter(
      (cat: Term) => cat.slug && !['startseite', 'vol'].includes(cat.slug)
    )

    workingPost.categoryClasses = ''
    if (workingPost.relevantCategories) {
      workingPost.categoryClasses = workingPost.relevantCategories
        .map((c) => `category-${c.slug}`)
        .join(' ')
    }

    workingPost.dateGmt =
      workingPost.dateGmt || format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")
    workingPost.link = generatePermalink(workingPost)
    workingPost.permalink = `${settings.publicUrl}${workingPost.link}`
    workingPost.enhanced = true
  }
  return workingPost
}

export const getRenderComponent = (post: EnhancedPost): string => {
  if (!post.hasPicture) {
    return 'NoPicture'
  }
  return 'Regular'
}

export const normalizeBlocks = (
  post: EnhancedPost,
  filterAuthor: boolean = true
): Block[] => {
  let blocks: Block[] = post.blocks.map((i: Block): Block => {
    const ot = i.ot ? i.ot : i.t
    return { t: i.t, a: i.a, h: i.h, ot }
  })

  // filter out article images if it's a comment. the image should
  // only be used on the list
  if (post.isComment) {
    blocks = blocks.filter(
      (block: Block) => !['russmedia/dcx-image', 'core/image'].includes(block.t)
    )
  }

  return blocks
    .map((b: Block): Block => {
      const o: Block = {
        ...b,
        ot: b.ot || b.t,
        h: b.h,
        a: b.a,
        t: b.t,
      }

      const handleAuthor = (
        b: Block,
        post: EnhancedPost,
        filterAuthor: boolean
      ) => {
        if (b.a) {
          const clsAttr = b.a.find(
            (i: BlockAttribute) => i.key === 'className'
          ) || {
            key: 'className',
            value: 'is-style-regular',
          }
          const cls = clsAttr.value?.match(/is-style-(.*)/)
          if (cls && (!filterAuthor || cls[1] !== 'comment')) {
            return {
              t: cls[1] === 'regular' ? BlockType.Author : `${cls[1]}-author`,
              data: post.author,
            }
          }
        }
        return null
      }

      if (b.ot === 'russmedia/apa-livestream') {
        o.t = 'APALivestream'
      } else if (b.ot === 'russmedia/post-author') {
        const author = handleAuthor(b, post, filterAuthor)
        if (author) {
          o.t = author.t
          o.data = author.data
        } else {
          o.t = BlockType.DELETE
        }
      } else if (b.ot === 'russmedia/dcx-image') {
        o.t = BlockType.DCXImage
      } else {
        o.t = BlockType.HtmlBlock
      }
      return o
    })
    .filter((i: Block) => i.ot !== BlockType.DELETE)
}

export const hasPicture = (p: EnhancedPost): boolean => {
  if (!p.thumbnail || p.thumbnail === null) return false
  return Object.prototype.hasOwnProperty.call(p.thumbnail, 'src')
}

export const generatePermalink = (
  p: EnhancedPost | Page,
  extension = 'vn'
): string => {
  if (p.type === 'page') {
    return `/${p.slug}`
  } else if (p.type === 'apa-ticker') {
    return `/newsticker/${p.slug}/${p.postId}`
  }
  let [cat]: Term[] = p.relevantCategories || []
  if (!cat) {
    cat = { slug: 'ohne-kategorie', name: '' }
  }
  const articleTs = toDate(parseISO(`${p.dateGmt}+0`))
  const formattedDate = format(articleTs, 'yyyy/MM/dd')

  return `/${cat.slug}/${formattedDate}/${p.slug}.${extension}`
}

export const getDescription = (p: EnhancedPost): string => {
  const postContent = p.blocks
    .filter((item) =>
      ['russmedia/nordstern-lead', 'core/paragraph'].includes(item.t)
    )
    .reduce((current, item) => current + item.h, '')
  const shortened =
    postContent.replace(/(<([^>]+)>)/gi, '').substring(0, 300) + '...'
  return shortened
}

export const generateOpenGraphMetaForPage = (_p: Page, _settings: any) => []

export const generateOpenGraphMeta = (_settings: any, _p?: EnhancedPost) => []

export const getGroupedPosts = (
  posts: EnhancedPost[],
  layouts: Layout[]
): PostsInLayout[] => {
  let availableLayouts = layouts.filter((l) => l.items > 0)

  const postsInLayout: PostsInLayout[] = posts.reduce<PostsInLayout[]>(
    (grouped: PostsInLayout[], post: Post) => {
      const currentGroup = grouped[grouped.length - 1]
      let layoutIdx = currentGroup.layout
      let currentLayout = availableLayouts[layoutIdx]
      if (currentGroup.posts.length === currentLayout.items) {
        layoutIdx++
        if (layoutIdx > availableLayouts.length - 1) {
          layoutIdx = 0
          availableLayouts = layouts.filter((item) => !item.skipOnRepeat)
        }
        currentLayout = availableLayouts[layoutIdx]
        grouped.push({
          layout: layoutIdx,
          component: currentLayout.component,
          posts: [],
        })
      }
      grouped[grouped.length - 1].posts.push(post)
      return grouped
    },
    [{ layout: 0, component: availableLayouts[0].component, posts: [] }]
  )
  return postsInLayout
}

export const generateLdPlusJson = (
  settings: any,
  post?: EnhancedPost
): object[] => {
  if (!post) return [{}]

  let postCategory: Term = {
    slug: 'ohne-kategorie',
    name: 'Ohne Kategorie',
  }
  if (post.relevantCategories && post.relevantCategories.length > 0) {
    postCategory = post.relevantCategories[0]
  }
  const pubUrl = settings.publicUrl
  let modifiedGmt = post.modifiedGmt
  const dateGmt = post.dateGmt

  if (
    parseISO(`${modifiedGmt}+0`).getTime() < parseISO(`${dateGmt}+0`).getTime()
  ) {
    modifiedGmt = dateGmt
  }

  return [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'NewsArticle',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': post.permalink,
        },
        image: {
          '@type': 'ImageObject',
          url: post.thumbnail
            ? `${pubUrl}${post.thumbnail.src}`
            : `${pubUrl}/icon.png`,
        },
        headline: entityDecode(post.title),
        author: {
          '@type': 'Person',
          name: post.author?.node.name || 'Redaktion',
        },
        datePublished: `${dateGmt}+00:00`,
        dateModified: `${modifiedGmt}+00:00`,
        publisher: {
          '@type': 'Organization',
          name: 'Vorarlberger Nachrichten',
          logo: {
            '@type': 'ImageObject',
            url: `${pubUrl}/icon.png`,
          },
          '@id': 'vn.at',
        },
        isAccessibleForFree: !!post.isFree,
        hasPart: {
          '@type': 'WebPageElement',
          cssSelector: '.paywalled-content',
          isAccessibleForFree: !!post.isFree,
        },
        isPartOf: {
          '@type': ['CreativeWork', 'Product'],
          name: 'post.title',
          productID: 'vn.at:showcase',
        },
      }),
    },
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': `${pubUrl}/ressort/${postCategory.slug}`,
              name: postCategory.name,
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': post.permalink,
              name: entityDecode(post.title),
            },
          },
        ],
      }),
    },
  ]
}

export const flatListToHierarchical = (data: MenuEntry[] = []): MenuItem[] => {
  const tree: MenuItem[] = []
  const childrenOf: { [id: string]: any } = {}
  data.forEach((item) => {
    const newItem: MenuItem = { ...item }
    const { id, parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem.childItems = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })
  return tree
}
