



















import BasePaywallLinks from '../../../base/components/paywall/PaywallLinks.vue'

export default class PaywallLinks extends BasePaywallLinks {}
