import { weatherMixin as baseWeatherMixin } from '../../base/mixins/weatherMixin.js'
import { spectrum } from '~components/header/weather-icons.js'

export const weatherMixin = {
  computed: {
    temperatureColor() {
      let matchingColor
      for (const t in spectrum) {
        if (parseInt(t) > this.data.temperature_int) break
        matchingColor = spectrum[t]
      }
      return matchingColor
    },
  },
  methods: baseWeatherMixin.methods,
}
