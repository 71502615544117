<template>
  <button
    v-if="$store.getters['weather/temperature'] !== ''"
    class="weather-button"
    @click.prevent="$store.dispatch('weather/toggleWidget')"
  >
    <svg
      viewBox="0 0 20 20"
      role="presentation"
      class="weather-button__icon"
      v-html="weatherIcon"
    />
    <div class="current-temp">
      <span class="sr-only">{{ $t('weather.current-temp') }}</span>
      {{ $store.getters['weather/temperature'] }}°
    </div>
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      class="weather-button__cheveron"
      :class="{
        'weather-button__cheveron--open': $store.getters['weather/showWidget'],
      }"
    >
      <path
        d="M10 12.95l-.35-.35L5.05 8l.7-.7 3.9 3.88.35.36.35-.36 3.9-3.89.7.71L10 12.95z"
      />
    </svg>
  </button>
</template>

<script>
import { icons, mappings } from '~components/header/weather-icons.js'

export default {
  computed: {
    weatherIcon() {
      const symbol = this.$store.getters['weather/symbol']
      return icons[mappings[symbol].toLowerCase()]
    },
  },
}
</script>

<style>
@import '~components/header/WeatherButton.css';
</style>
