

















































































































import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  filters: {
    pretty(value: object) {
      return JSON.stringify(value, null, 2)
    },
  },
})
export default class AppDebug extends Vue {
  private resetState: string = ''
  private platform: string = this.$store.getters['app/platform']
  private whitelisted: boolean = this.$store.getters['app/isWhitelisted']
  private offerInAppPurchases: boolean =
    this.$store.getters['app/offerInAppPurchases']

  private inBrowser: boolean = this.$store.getters['app/handleInBrowser']
  private isAppDebugOpen: boolean = this.$store.getters['app/isAppDebugOpen']

  reset() {
    this.$store.dispatch('app/resetStateMachine', this.resetState)
  }

  reloadView() {
    window.location.reload()
  }

  @Watch('platform')
  onPlatformChanged(to: string) {
    this.$store.commit('app/SET_PLATFORM', to)
  }

  @Watch('whitelisted')
  onWhitelistedChanged(to: boolean) {
    const clientVersion = to ? 'VNTest/6200/6200' : 'VN/1234/1234'
    this.$store.commit('app/SET_VERSION', clientVersion)
  }

  @Watch('offerInAppPurchases')
  onOfferInAppPurchasesChanged(to: boolean) {
    this.$store.commit('app/OFFER_IN_APP_PURCHASES', to)
  }

  @Watch('inBrowser')
  onInBrowserChanged(to: boolean) {
    this.$store.commit('app/HANDLE_IN_BROWSER', to)
  }

  @Watch('isAppDebugOpen')
  onIsAppDebugOpenChanged(to: boolean) {
    this.$store.commit('app/APP_DEBUG_OPEN', to)
  }

  get cookies() {
    const allCookies = { ...this.$cookies.getAll() }
    const otherblacklisted = ['ioam2018', 'xbc', 'POPUPCHECK']
    for (const key in allCookies) {
      if (
        (!key.includes('__app') && key.indexOf('_') === 0) ||
        otherblacklisted.includes(key)
      ) {
        delete allCookies[key]
      }
    }
    return allCookies
  }
}
