<template>
  <nav :aria-label="$t('a11y.screen-reader-aid')" class="a11y-jumpmarks">
    <a v-t="`a11y.to-content`" accesskey="1" href="#main" />
    <span class="sr-only"> / </span>
    <a v-if="onDetailPage" accesskey="3" href="#article-content">{{
      $t('a11y.read-content')
    }}</a>
  </nav>
</template>

<script>
export default {
  props: {
    onDetailPage: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
@import '~components/header/JumpMarks.css';
</style>
