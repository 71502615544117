<template>
  <div v-if="error && error.message">
    <div v-for="(e, i) in errors" :key="i" class="my-4 bg-red p-2 text-white">
      <p
        :inner-html.prop="e.message.replace('GraphQL error: ', '') | ssoError"
        data-test="graphql-error"
        class="error-message m-0 font-headline text-lg font-semibold"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    error: {
      type: [Object, Error],
      default: undefined,
    },
  },
  computed: {
    errors() {
      if (
        this.error.networkError &&
        this.error.networkError.result &&
        this.error.networkError.result.errors.length
      ) {
        return this.error.networkError.result.errors
      }
      return [this.error]
    },
  },
}
</script>
