












import { Vue, Component, Prop } from 'vue-property-decorator'
import SidebarWidget from '~components/sidebar/SidebarWidget.vue'

@Component({
  components: {
    SidebarWidget,
  },
})
export default class Sidebar extends Vue {
  @Prop({ type: String, default: 'frontpage' }) readonly position!: string

  get widgets(): object[] {
    const getters = this.$store.getters
    return getters['frontpage/getSidebarByPosition'](this.position)?.widgets
  }
}
