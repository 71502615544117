










import { Component } from 'vue-property-decorator'
import DefaultLayout from '~base/layouts/default.vue'
import Header from '~components/header/Header.vue'

@Component({
  components: {
    Header,
  },
  head() {
    return {
      script: [
        {
          innerHTML: `
            if('serviceWorker'in navigator&&!('getRegistrations'in navigator.serviceWorker)){navigator.serviceWorker.getRegistrations=function(){return new Promise(function(resolve,reject){resolve([])})}}
          `,
        },
        {
          innerHTML: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${this.$config.gtmTag}');
      `,
        },
        {
          innerHTML: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '298211937927823');
          `,
        },
        {
          src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
          async: true,
        },
        {
          innerHTML: `
      window.googletag = window.googletag || { cmd: []}
      window.rmd_nativeslots = window.rmd_nativeslots || []

      window.googletag.cmd.push(function () {
        window.rmdMappingSkyscraper1 = googletag.sizeMapping().
          addSize([1024, 600], [[300, 600], [160, 600]]).
          addSize([0, 0], []).
          build();
        window.rmdMappingContentad1Mobile = googletag.sizeMapping().
          addSize([320,50], [[320, 250], [300, 250], [320, 50], [300, 50], 'fluid']).
          addSize([300,50], [[300, 250], [300, 50], 'fluid']).
          addSize([0, 0], []).
          build();

        window.googletag.pubads().enableSingleRequest()
        window.googletag.pubads().collapseEmptyDivs()
        window.googletag.pubads().disableInitialLoad()
        window.googletag.pubads().setRequestNonPersonalizedAds(1)
        window.googletag.enableServices()
      })
      `,
        },
      ],
      noscript: [
        {
          innerHTML: `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${this.$config.gtmTag}" height="0" width="0"
      style="display:none;visibility:hidden"></iframe>
      `,
        },
        {
          innerHTML: `
      <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=298211937927823&ev=PageView&noscript=1"/>
          `,
        },
      ],
      __dangerouslyDisableSanitizers: ['script', 'noscript'],
    }
  },
})
export default class PromoLayout extends DefaultLayout {}
