<template>
  <header
    class="fixed top-0 z-top w-full border-b border-black-800 bg-white py-2"
    role="banner"
  >
    <div
      class="mx-auto flex w-full items-center justify-between px-2 md:container md:px-4 lg:px-6"
    >
      <Logo class="z-30 mr-2 h-8 w-8" />
      <div class="flex items-center">
        <LoginLogout :short="true" />
        <WeatherButton
          class="flex md:hidden"
          data-testid="weather-button-mobile"
        />
        <MenuButton :on-background-image="false" class="mobile-menu-opener" />
      </div>
    </div>
    <Nav class="z-20" />
    <WeatherWidget v-if="$store.getters['weather/showWidget']" />
  </header>
</template>

<script>
import Logo from '~components/header/Logo.vue'
import MenuButton from '~components/header/MenuButton.vue'
import Nav from '~components/header/Nav.vue'
import LoginLogout from '~components/header/LoginLogout.vue'
import WeatherButton from '~components/header/WeatherButton.vue'
import WeatherWidget from '~components/header/WeatherWidget.vue'

export default {
  components: {
    Logo,
    MenuButton,
    Nav,
    LoginLogout,
    WeatherButton,
    WeatherWidget,
  },
}
</script>
