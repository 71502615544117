import { InMemoryCache } from 'apollo-cache-inmemory'
import * as fetch from 'isomorphic-fetch'

export default function (ctx) {
  const customFetch = (uri, options) => {
    const queryBody = JSON.parse(options.body)
    if (
      ctx.$config.environment === 'local' ||
      ctx.$config.environment === 'dev' ||
      process.server
    ) {
      console.log(
        `🚀 querying %c${queryBody.operationName}%c with variables %o`,
        'color: #ebf8ff; background-color: #4299e1; padding: 2px 4px; font-weight: bold; border-radius: 3px',
        'color: initial; background-color: initial',
        queryBody.variables
      )
    }
    return fetch(uri, options)
  }

  return {
    cache: new InMemoryCache(),
    httpEndpoint: `${ctx.$config.scheduledDataService}/graphql`,
    httpLinkOptions: {
      fetch: customFetch,
    },
  }
}
