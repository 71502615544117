import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  EnergyDailyData,
  EnergyMonthlyData,
  EnergyWeeklyData,
} from '../common/types'

export const state = () => ({
  daily: [] as EnergyDailyData[],
  weekly: [] as EnergyWeeklyData[],
  monthly: [] as EnergyMonthlyData[],
  fetched: false,
})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  async fetchData({ commit, getters }) {
    if (getters.fetched) return
    commit('SET_DATA_FETCHED')
    try {
      const [daily, weekly, monthly] = await Promise.all([
        fetch(
          `${this?.app?.$config?.scheduledDataService}/api/daily-data?since=90`
        ).then((res) => res.json()),
        fetch(
          `${this?.app?.$config?.scheduledDataService}/api/weekly-data?weeks=52`
        ).then((res) => res.json()),
        fetch(
          `${this?.app?.$config?.scheduledDataService}/api/monthly-data`
        ).then((res) => res.json()),
      ])
      commit('SET_DAILY_DATA', daily)
      commit('SET_WEEKLY_DATA', weekly)
      commit('SET_MONTHLY_DATA', monthly)
    } catch (e) {
      console.error(`⚙ error fetching corona data`, e)
    }
  },
}

export const mutations: MutationTree<RootState> = {
  SET_DATA_FETCHED(currentState) {
    currentState.fetched = true
  },
  SET_DAILY_DATA(currentState, data) {
    currentState.daily = data
  },
  SET_WEEKLY_DATA(currentState, data) {
    currentState.weekly = data
  },
  SET_MONTHLY_DATA(currentState, data) {
    currentState.monthly = data
  },
}

export const getters: GetterTree<RootState, RootState> = {
  monthly: ({ monthly }) => monthly,
  weekly: ({ weekly }) => weekly,
  daily: ({ daily }) => daily,
}
