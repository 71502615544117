import { getters as baseGetters } from '../../base/store/news'
export { state, mutations, actions } from '../../base/store/news'

export const getters = {
  ...baseGetters,
  tagsSettings: () => ({
    webinar: {
      headerComponent: `Webinar`,
    },
    coronaimpfung: {
      headerComponent: `CoronaVaccinations`,
    },
    'grund-und-boden': {
      headerComponent: `GrundUndBoden`,
    },
    'olympische-spiele': {
      headerComponent: `Bejing2022`,
    },
    'einfach-geniessen': {
      layouts: [
        {
          component: () =>
            import(
              /* webpackChunkName: "einfach-genießen-layout" */ `~components/content/layouts/Layout-1.vue`
            ),
          items: 5,
        },
      ],
    },
  }),
  categorySettings: () => ({
    regionalumfrage: {
      headerComponent: () =>
        import(
          /* webpackChunkName: "cat-header-[request]" */ `~components/content/widgets/listings/RegionalQuestionaire.vue`
        ),
    },
    vorarlberg: {
      sidebar: 'locality',
    },
    eliteliga: {
      sidebar: 'eliteliga',
    },
    regionalliga: {
      sidebar: 'eliteliga',
    },
    vorteilsclub: {
      headerComponent: 'VorteilsclubHeader',
      sidebar: 'vorteilsclub',
      layouts: [
        {
          component: () =>
            import(
              /* webpackChunkName: "vorteilsclub-layout" */ `~components/content/layouts/WithExcerptBig.vue`
            ),
          items: 1,
          skipOnRepeat: true,
        },
        {
          component: () =>
            import(
              /* webpackChunkName: "vorteilsclub-layout" */ `~components/content/layouts/WithExcerptSmall.vue`
            ),
          items: 2,
        },
      ],
    },
  }),
}
