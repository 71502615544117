export default async function (context) {
  const app = context.app
  const nordsternCookie = app.$cookies.get('__ut')
  const vnCookie = app.$cookies.get('vmhsso')

  if (!nordsternCookie && vnCookie) {
    const loginBody = {}
    vnCookie.split('&').forEach((pair) => {
      const [k, v] = pair.split('=')
      if (k === 't') loginBody.token = v
      else if (k === 'h') loginBody.hash = v
    })

    try {
      const response = await app.$axios({
        method: 'POST',
        url: `${context.$config.paywallEndpoint}/hashtokenlogin`,
        data: loginBody,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.data && response.data.userRef) {
        app.$cookies.set(
          `_vn_ex`,
          JSON.stringify({
            displayName: response.data.displayName,
            userRef: response.data.userRef,
            capabilities: response.data.capabilities,
          })
        )
      }
    } catch (e) {
      // the paywall responds with status != 200 on any error, just ignore in this case.
    }
  }
}
