export const state = () => ({
  items: [],
  open: false,
})

export const actions = {
  async fillmenu() {},
  toggleMenu({ commit }) {
    commit('TOGGLE_MENU')
  },
  closeMenu({ commit }) {
    commit('CLOSE_MENU')
  },
}

export const mutations = {
  TOGGLE_MENU(currentState) {
    currentState.open = !currentState.open
  },
  CLOSE_MENU(currentState) {
    currentState.open = false
  },
}

export const getters = {
  items: (currentState) => currentState.items,
  isOpen: (currentState) => currentState.open,
}
