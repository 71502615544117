<template>
  <div
    class="search-select"
    :class="{ 'is-active': isOpen, 'is-multiple': multiple }"
  >
    <button
      ref="button"
      type="button"
      class="search-select-input"
      @click="toggle"
    >
      <span v-if="internalValue.length !== 0">
        <ul>
          <li
            v-for="selected in internalValue"
            :key="selected"
            class="search-select-value"
          >
            {{ selected }}
            <span
              v-if="multiple"
              class="search-select-remove"
              @click.stop="removeElement(selected)"
            ></span>
          </li>
        </ul>
      </span>
      <span v-else class="search-select-placeholder">{{ placeholder }}</span>
      <div>
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          class="search-select-cheveron"
        >
          <path
            d="M10 12.95l-.35-.35L5.05 8l.7-.7 3.9 3.88.35.36.35-.36 3.9-3.89.7.71L10 12.95z"
          />
        </svg>
      </div>
    </button>
    <OnClickOutside v-if="isOpen" :do="close">
      <div v-show="isOpen" class="search-select-dropdown">
        <input
          ref="search"
          :value="search"
          class="search-select-search"
          :placeholder="placeholder"
          @input="search = $event.target.value"
          @keydown.esc="close"
          @keydown.up="highlightPrev"
          @keydown.down="highlightNext"
          @keydown.enter.prevent="selectHighlighted"
          @keydown.tab.prevent
        />
        <ul
          v-show="filteredOptions.length > 0"
          ref="options"
          class="search-select-options"
        >
          <li
            v-for="(option, i) in filteredOptions"
            :key="option"
            class="search-select-option"
            :class="{
              'is-active': i === highlightedIndex,
              'is-selected': isSelected(option),
            }"
            @click="select(option)"
          >
            {{ option }}
          </li>
        </ul>
        <div v-show="filteredOptions.length === 0" class="search-select-empty">
          {{ $t('dropdown.no-results', [search]) }}
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>

<script>
import OnClickOutside from '~components/utils/OnClickOutside.vue'

export default {
  components: {
    OnClickOutside,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    filterFunction: {
      type: Function,
      default(search, options) {
        return options.filter((option) =>
          option.toLowerCase().startsWith(search.toLowerCase())
        )
      },
    },
    placeholder: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      isOpen: false,
      search: '',
      highlightedIndex: 0,
    }
  },
  computed: {
    filteredOptions() {
      return this.filterFunction(this.search, this.options)
    },
    internalValue() {
      return this.value || this.value === 0
        ? Array.isArray(this.value)
          ? this.value
          : [this.value]
        : []
    },
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.close()
      } else {
        this.open()
      }
    },
    open() {
      if (this.isOpen) {
        return
      }
      this.isOpen = true
      const highlightLastItem = this.filteredOptions.findIndex(
        (o) => o === this.internalValue[this.internalValue.length - 1]
      )

      this.highlightedIndex = highlightLastItem > 0 ? highlightLastItem : 0

      this.$nextTick(() => {
        this.$refs.search.focus()
        this.scrollToHighlighted()
      })
    },
    close() {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
      this.$refs.button.focus()
    },
    isSelected(option) {
      return this.internalValue.includes(option)
    },
    select(option) {
      // Pressed enter on invalid search
      if (!option) {
        return
      }

      this.isSelected(option)
        ? this.removeElement(option)
        : this.addElement(option)

      this.search = ''
      this.highlightedIndex = 0
      this.close()
    },
    addElement(option) {
      if (this.multiple) {
        this.$emit('input', this.internalValue.concat([option]))
      } else {
        this.$emit('input', option)
      }
    },
    removeElement(option) {
      if (this.multiple && this.isSelected(option)) {
        const index = this.internalValue.indexOf(option)
        const newValue = this.internalValue
          .slice(0, index)
          .concat(this.internalValue.slice(index + 1))
        this.$emit('input', newValue)
      }
    },
    selectHighlighted() {
      this.select(this.filteredOptions[this.highlightedIndex])
    },
    scrollToHighlighted() {
      const item = this.$refs.options.children[this.highlightedIndex]

      if (item !== undefined) {
        item.scrollIntoView({
          block: 'nearest',
        })
      }
    },
    highlight(index) {
      this.highlightedIndex = index

      if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.filteredOptions.length - 1
      }

      if (this.highlightedIndex > this.filteredOptions.length - 1) {
        this.highlightedIndex = 0
      }

      this.scrollToHighlighted()
    },
    highlightPrev() {
      this.highlight(this.highlightedIndex - 1)
    },
    highlightNext() {
      this.highlight(this.highlightedIndex + 1)
    },
  },
}
</script>

<style>
@import '~components/utils/DropDown.css';
</style>
