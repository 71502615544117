import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  CVDistrictData,
  CVRegionData,
  CVCountryData,
} from '../components/coronavirus/types'

export const state = () => ({
  districtdata: undefined as CVDistrictData | undefined,
  regiondata: undefined as CVRegionData | undefined,
  countrydata: undefined as CVCountryData | undefined,
  lastUpdate: '' as string,
  fetched: false,
})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  async fetchData({ commit, getters }) {
    if (getters.fetched) return
    commit('SET_DATA_FETCHED')
    try {
      const query = require('~base/graphql/queries/CoronaAgesData.gql')
      const apolloClient = this?.app?.apolloProvider?.clients?.scheduledData
      if (apolloClient) {
        const response = await apolloClient.query({
          query,
          variables: {
            limit: 40,
          },
        })
        commit('SET_DISTRICT_DATA', response.data.districtdata)
        commit('SET_REGION_DATA', response.data.regiondata)
        commit('SET_COUNTRY_DATA', response.data.countrydata)
        commit('SET_LAST_UPDATE', response.data.lastupdate)
      }
    } catch (e) {
      console.error(`⚙ error fetching corona data`, e)
    }
  },
}

export const mutations: MutationTree<RootState> = {
  SET_DATA_FETCHED(currentState) {
    currentState.fetched = true
  },
  SET_DISTRICT_DATA(currentState, data) {
    currentState.districtdata = data
  },
  SET_REGION_DATA(currentState, data) {
    currentState.regiondata = data
  },
  SET_COUNTRY_DATA(currentState, data) {
    currentState.countrydata = data
  },
  SET_LAST_UPDATE(currentState, data: string) {
    currentState.lastUpdate = data
  },
}

export const getters: GetterTree<RootState, RootState> = {
  districtData: ({ districtdata }) => districtdata,
  regionData: ({ regiondata }) => regiondata,
  countryData: ({ countrydata }) => countrydata,
  lastUpdate: ({ lastUpdate }): string => lastUpdate,
}
