import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { CookiebotConsent } from '~base/common/types'

interface ExtWindow extends Window {
  Cookiebot: {
    consent: CookiebotConsent
  }
}

declare let window: ExtWindow

export const state = () => ({
  consent: {
    necessary: false,
    marketing: false,
    preferences: false,
    statistics: false,
  } as CookiebotConsent,
})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  initializeGdpr({ commit }) {
    console.log(`🍪 initializing...`)
    const $cookies = this.$cookies
    const consentCookie = $cookies.get(`CookieConsent`)
    if (consentCookie) {
      // the cookie consent cookie sadly is not a json serialized javascript
      // object but rather a serialized javascript object. Therefore its content
      // needs to be decoded, certain characters replaced and finally it can be
      // parsed. The replacement has been taken out of the original uc.js file
      // located at https://consent.cookiebot.com/uc.js
      const decodedConsentCookie = decodeURIComponent(consentCookie)
      const consentJSON = decodedConsentCookie
        .replace(/%2c/g, ',')
        .replace(/'/g, '"')
        .replace(/([{[,])\s*([a-zA-Z0-9_]+?):/g, '$1"$2":')
      const consentObject = JSON.parse(consentJSON)
      commit(`SET_CONSENT`, {
        necessary: consentObject.necessary,
        preferences: consentObject.preferences,
        statistics: consentObject.statistics,
        marketing: consentObject.marketing,
      })
    }
  },
  initializeGdprClient({ commit }) {
    console.log(`🍪 initializing...`)

    const handleCookiebotEvent = () => {
      const consent = window.Cookiebot.consent
      commit(`SET_CONSENT`, {
        necessary: consent.necessary,
        preferences: consent.preferences,
        statistics: consent.statistics,
        marketing: consent.marketing,
      })
    }

    if (process.client) {
      window.addEventListener(`CookiebotOnAccept`, handleCookiebotEvent)
      window.addEventListener(`CookiebotOnDecline`, handleCookiebotEvent)
    }
  },
}

export const mutations: MutationTree<RootState> = {
  SET_CONSENT(currentState: RootState, consent: CookiebotConsent) {
    currentState.consent = consent
  },
}

export const getters: GetterTree<RootState, RootState> = {
  consent: ({ consent }) => consent,
}
