import Vue from 'vue'
import VueI18n from 'vue-i18n'
import lang from '~base/lang/de.js'

Vue.use(VueI18n)

export default ({ app }) => {
  app.i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: { de: lang },
  })
}
