export default function (context) {
  if (process.server) {
    const dns = require('dns')
    const bots = [
      {
        ua: /googlebot/i,
        host: /googlebot.com$/,
      },
      {
        ua: /facebookexternalhit/i,
        host: /facebook.com$/,
      },
      {
        ua: /cXensebot/i,
        host: /cxensebot.com$/,
      },
      {
        ua: /echoboxbot/i,
        host: /echobox.com$/,
      },
    ]
    const { req, store } = context
    const userAgent = req.headers['user-agent']
    const ip =
      req.headers['x-forwarded-for'] ||
      req.connection.remoteAddress ||
      req.socket.remoteAddress ||
      (req.connection.socket ? req.connection.socket.remoteAddress : null)
    if (!ip) return
    const useIp = ip
      .split(', ')
      .filter(
        (i) => !i.trim().includes('194.208.') && !i.trim().includes('194.183.')
      )[0]
    const maybeBotRequest = bots.filter((b) => userAgent.match(b.ua))
    if (maybeBotRequest.length === 0) return
    dns.reverse(useIp, (err, hostnames) => {
      if (err) return
      if (hostnames.length > 0 && hostnames[0].match(maybeBotRequest.host)) {
        dns.lookup(hostnames[0], (err, address) => {
          if (err) return
          if (address === useIp) {
            store.dispatch('paywall/setIsBotRequest')
          }
        })
      }
    })
  }
}
