import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { addFilter } from '@russmedia/hooks'

const geaLog = (message: string, ...args: any[]) => {
  console.log(`📰`, message, ...args)
}

interface ExtWindow extends Window {
  GaaMetering: {
    init: (settings: any) => void
    getGaaUserPromise: () => Promise<any>
    getLoginPromise: () => Promise<any>
    registerUserPromise: () => Promise<any>
  }

  SWG: {
    push: (subscriptions: any) => void
  }
  tp: any

  GaaMeteringRegwall: {
    show: (settings: any) => Promise<any>
    remove: () => void
  }
}
declare let window: ExtWindow

type GoogleSignInDetails = {
  givenName: string
  familyName: string
  email: string
  imgUrl: string
  name: string
  idToken: string
}

type GoogleEntitlement = {
  products: string[]
  source: string
  subscriptionToken: string
}

export const state = () => ({
  isFromGoogleShowcase: false as boolean,
  useGoogleWorkflow: true as boolean,
  googleSignInDetails: undefined as GoogleSignInDetails | undefined,
  googleEntitlements: undefined as GoogleEntitlement[] | undefined,
  subscriptions: null as any,
  callbacksRegistered: false as boolean,
  initialized: false as boolean,
})

export type RootState = ReturnType<typeof state>

const parseJwt = (token: string): any => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

const registerUser = (email: string): Promise<any> => {
  const mutation = require('~base/graphql/mutations/RegisterGEA.gql')
  const apolloClient = window.$nuxt.$apolloProvider?.defaultClient
  return apolloClient
    ?.mutate({
      mutation,
      variables: {
        email,
      },
    })
    .then(({ data }) => ({
      id: data.registerGEA.result.metering.state.id,
      grantReason: 'SUBSCRIBER',
      granted: true,
      subscriptionTimestamp:
        data.registerGEA.result.metering.state.standardAttributes
          .registered_user.timestamp,
      registrationTimestamp:
        data.registerGEA.result.metering.state.standardAttributes
          .registered_user.timestamp,
    }))
}

const isUserRegistered = (): boolean => {
  return window.tp.user.isUserValid()
}

type UserState = {
  id?: string
  granted: boolean
  grantReason?: 'METERING' | 'SUBSCRIBER'
  registrationTimestamp?: number
  subscriptionTimestamp?: number
}

const getUserState = (): UserState => {
  const userState: UserState = {
    granted: isUserRegistered(),
  }
  return userState
}

export const actions: ActionTree<RootState, RootState> = {
  /**
   * initializes the filters required for handling öpiano events
   */
  initialize({ getters, commit }) {
    const geaSignInDetails = localStorage.getItem(`geaSignInDetails`)
    if (geaSignInDetails !== null) {
      commit(`SET_GOOGLE_SIGN_IN_DETAILS`, JSON.parse(geaSignInDetails))
    }
    addFilter('piano/customTags', (tags: string[]) => {
      if (getters.isFromGoogleShowcase && getters.useGoogleWorkflow)
        tags.push('GOOGLE_EXTENDED_ACCESS')
      return tags
    })
  },
  handleShowcase({ getters, dispatch, commit, rootGetters }) {
    if (getters.initialized) {
      geaLog(`already initialized...`)
      return
    }
    const pianoEntitlementsPromise = new Promise((resolve) => {
      // wait for tp to be initialized
      const resolveFromPiano = (data: any) => {
        console.log(`🔥 from piano!`, data)
        const userState = getUserState()
        if (data.data.length > 0) {
          userState.id = data.data[0].user.uid
          userState.grantReason = 'SUBSCRIBER'
          userState.granted = true
          userState.subscriptionTimestamp = data.data[0].user.create_date
          userState.registrationTimestamp = data.data[0].user.create_date
        }
        resolve(userState)
      }

      const checker = () => {
        if (!window.tp?.api?.callApi) {
          geaLog(`tp not available yet..., trying again in 100ms`)
          window.setTimeout(checker, 100)
        } else {
          window.tp.api.callApi(
            `/access/list`,
            { aid: window.tp.aid },
            resolveFromPiano
          )
        }
      }
      checker()
    })

    geaLog(`This is coming from the google extended showcase experience`)
    document.body.classList.add('in-google-showcase')

    function executePianoExperience() {
      geaLog(`show the regular piano experience`)
      if (getters.googleSignInDetails) {
        commit(
          'paywall/SET_PREFILL_REGISTRATION_DATA',
          {
            firstname: getters.googleSignInDetails.givenName,
            lastname: getters.googleSignInDetails.familyName,
            email: getters.googleSignInDetails.email,
          },
          { root: true }
        )
      }
      commit('SET_USE_GOOGLE_WORKFLOW', false)
      const currentPost = rootGetters['news/currentPost']
      dispatch('paywall/executeExperience', currentPost, {
        root: true,
      })
    }

    dispatch(
      'paywall/showingPianoOffer',
      { displayMode: 'inline' },
      { root: true }
    )
    commit('SET_GOOGLE_SHOWCASE_INITIALIZED')

    window.GaaMetering.init({
      googleApiClientId:
        '765435528906-jh40ku11so6l5n1oaftr3a6e9rct93m4.apps.googleusercontent.com',

      userState: getUserState(),
      allowedReferrers: ['dev-nordstern.vn.at', 'www.vn.at'],
      handleLoginPromise: new Promise((resolve) => {
        window.GaaMetering.getLoginPromise().then(() => {
          geaLog(`in handleLoginPromise`)
          window.GaaMeteringRegwall.remove()

          window.$nuxt.$store.dispatch(`paywall/showLoginFormForDisplayMode`, {
            displayMode: 'modal',
            showCloseButton: true,
            closeCallback: () => {
              resolve(getUserState())
            },
          })
        })
      }),
      registerUserPromise: new Promise((resolve) => {
        window.GaaMetering.getGaaUserPromise().then((gaaUser) => {
          geaLog(`in registerUser`, gaaUser)
          const jwt = parseJwt(gaaUser.credential)
          commit(`SET_GOOGLE_SIGN_IN_DETAILS`, jwt)
          if (jwt && jwt.email) {
            resolve(registerUser(jwt.email))
          }
        })
      }),
      publisherEntitlementPromise: pianoEntitlementsPromise,
      unlockArticle: () => {
        geaLog(`unlocking article as google said so`)
        dispatch(`paywall/unlockArticle`, {}, { root: true })
      },
      showPaywall: () => {
        geaLog(`in showPaywall`)
        executePianoExperience()
      },
      handleSwGEntitlement: () => {}, // unused
    })
  },
}

export const mutations: MutationTree<RootState> = {
  SET_FROM_GOOGLE_SHOWCASE(currentState) {
    currentState.isFromGoogleShowcase = true
  },
  SET_USE_GOOGLE_WORKFLOW(currentState, useGoogleWorkflow = true) {
    currentState.useGoogleWorkflow = useGoogleWorkflow
  },
  CALLBACKS_REGISTERED(currentState, callbacksRegistered) {
    currentState.callbacksRegistered = callbacksRegistered
  },
  SET_GOOGLE_SIGN_IN_DETAILS(currentState, signInDetails: GoogleSignInDetails) {
    currentState.googleSignInDetails = signInDetails
    localStorage.setItem('geaSignInDetails', JSON.stringify(signInDetails))
  },
  SET_GOOGLE_SHOWCASE_INITIALIZED(currentState) {
    currentState.initialized = true
  },
}

export const getters: GetterTree<RootState, RootState> = {
  isFromGoogleShowcase: ({ isFromGoogleShowcase }) => isFromGoogleShowcase,
  useGoogleWorkflow: ({ useGoogleWorkflow }) => useGoogleWorkflow,
  callbacksRegistered: ({ callbacksRegistered }) => callbacksRegistered,
  googleSignInDetails: ({ googleSignInDetails }) => googleSignInDetails,
  initialized: ({ initialized }) => initialized,
}
