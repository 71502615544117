import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { Sidebar, SidebarWidget, FrontpageSettings } from '~base/common/types'

export const state = () => ({
  settings: {} as FrontpageSettings,
  sidebars: [] as Sidebar[],
})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  async fetchFrontpageSettings({ commit }) {
    try {
      const frontpageSettings = require('~base/graphql/queries/FrontpageSettings.gql')
      const apolloClient = this?.app?.apolloProvider?.defaultClient
      if (apolloClient) {
        const response = await apolloClient.query({
          query: frontpageSettings,
          variables: {
            request: '{}',
          },
        })
        if (response.data.frontpageSettings) {
          commit('SET_SETTINGS_DATA', response.data.frontpageSettings)
        }
        if (response.data.getSidebars) {
          commit('SET_SIDEBARS', response.data.getSidebars)
        }
      } else {
        throw new Error('Unable to get to Apollo Client')
      }
    } catch (e) {
      console.error(`⚙ error fetching frontpage setup`, e)
    }
  },
}

export const mutations: MutationTree<RootState> = {
  SET_SETTINGS_DATA(currentState, data) {
    currentState.settings = data
  },
  SET_SIDEBARS(currentState, data) {
    currentState.sidebars = data
  },
}

export const getters: GetterTree<RootState, RootState> = {
  settings: ({ settings }) => settings,

  sidebars: ({ sidebars }) => sidebars,
  getSidebarByPosition:
    ({ sidebars }) =>
    (position: string) =>
      sidebars.find((sidebar: Sidebar) => sidebar.position === position) ||
      sidebars[0],
  getWidgetsAtIndex: (_state, getters) => (position: string, index: number) => {
    const sidebar = getters.getSidebarByPosition(position)
    if (!sidebar) {
      return []
    }
    const foundWidgets = sidebar.widgets.filter(
      (w: SidebarWidget) => w.showOnMobile && w.posInMainFeed === index
    )
    return foundWidgets
  },
  hasWidgetAtIndex: (_state, getters) => (position: string, index: number) =>
    getters.getWidgetsAtIndex(position, index).length > 0,
}
