













































import { Component, Vue, Watch } from 'vue-property-decorator'
import Header from '~components/header/Header.vue'
import Footer from '~components/footer/Footer.vue'
import MaintenanceHeader from '~components/header/MaintenanceHeader.vue'
import JumpMarks from '~components/header/JumpMarks.vue'
import MaintenanceFooter from '~components/footer/MaintenanceFooter.vue'
import IEWarning from '~components/header/IEWarning.vue'
import AppDebug from '~components/app/AppDebug.vue'
import { CookiebotConsent, ScriptsAfterGdprType } from '~base/common/types'

const showCaseScripts = [
  {
    src: 'https://news.google.com/swg/js/v1/swg-gaa.js',
    async: true,
  },
  {
    async: true,
    'subscriptions-control': 'manual',
    src: 'https://news.google.com/swg/js/v1/swg.js',
  },
  {
    src: 'https://accounts.google.com/gsi/client',
    async: true,
    defer: true,
  },
]

@Component({
  components: {
    Header,
    Footer,
    JumpMarks,
    IEWarning,
    MaintenanceHeader,
    MaintenanceFooter,
    AppDebug,
  },
  head() {
    return {
      link: [
        {
          rel: 'preload',
          href: 'https://data-be4fd7bada.vn.at/iomm/latest/manager/base/es6/bundle.js',
          as: 'script',
          crossorigin: true,
          id: 'IOMmBundle',
        },
        {
          rel: 'preload',
          href: 'https://data-be4fd7bada.vn.at/iomm/latest/bootstrap/loader.js',
          as: 'script',
          crossorigin: true,
        },
      ],
      meta: [
        {
          name: `google-signin-client_id`,
          content: `765435528906-jh40ku11so6l5n1oaftr3a6e9rct93m4.apps.googleusercontent.com`,
        },
        {
          name: 'apple-itunes-app',
          content: 'app-id=417731767',
        },
      ],
      script: [
        {
          innerHTML: `
            if('serviceWorker'in navigator&&!('getRegistrations'in navigator.serviceWorker)){navigator.serviceWorker.getRegistrations=function(){return new Promise(function(resolve,reject){resolve([])})}}
          `,
        },
        {
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': '06d5dad0-840c-48ee-bd35-098d99d2e929',
          'data-framework': 'TCFv2.2',
          async: true,
        },
        {
          src: 'https://data-be4fd7bada.vn.at/iomm/latest/bootstrap/loader.js',
          crossorigin: true,
        },
        ...(this.$store.getters['google-showcase/isFromGoogleShowcase']
          ? showCaseScripts
          : []),
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }
  },
})
export default class DefaultLayout extends Vue {
  scriptsAfterGdpr: ScriptsAfterGdprType = {
    necessary: [
      {
        src: this.$config.pianoSandbox
          ? `//sandbox.tinypass.com/api/tinypass.min.js`
          : `//experience.tinypass.com/xbuilder/experience/load?aid=${this.$config.pianoAppId}`,
        async: true,
      },
      {
        src: 'https://www.googletagservices.com/tag/js/gpt.js',
        async: true,
      },
      {
        innerHTML: `
        window.googletag = window.googletag || {}
        window.googletag.cmd = window.googletag.cmd || []
        window.rmd_nativeslots = window.rmd_nativeslots || []
        window.googletag.cmd.push(function () {
          window.googletag.pubads().enableSingleRequest()
          window.googletag.pubads().collapseEmptyDivs()
          window.googletag.pubads().disableInitialLoad()
          window.googletag.enableServices()
        })
        `,
      },
    ],
    statistics: [
      {
        innerHTML: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${this.$config.gtmTag}');
            `,
      },
      {
        src: 'https://static.chartbeat.com/js/chartbeat_mab.js',
        async: true,
      },
      {
        innerHTML: `
        (function () {
        /** CONFIGURATION START **/
        var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
        _sf_async_config.uid = 27420;
        _sf_async_config.domain = 'vn.at';
        _sf_async_config.useCanonical = true;
        _sf_async_config.useCanonicalDomain = true;
        _sf_async_config.flickerControl = false;
        /** CONFIGURATION END **/
        function loadChartbeat() {
            var e = document.createElement('script');
            var n = document.getElementsByTagName('script')[0];
            e.type = 'text/javascript';
            e.async = true;
            e.src = '//static.chartbeat.com/js/chartbeat.js';
            n.parentNode.insertBefore(e, n);
        }
        loadChartbeat();
        })();
        `,
      },
      {
        innerHTML: `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
          ga('create', 'UA-122952244-1', 'auto');
          ga('require', 'GTM-MZBMFDZ');
        `,
      },
      {
        src: 'https://pp.lp4.io/app/60/12/79/601279d6588d3e6996624fe2.js',
        async: true,
      },
      {
        innerHTML: `
          (function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
          p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)};
          p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;n.src=w;
          g.parentNode.insertBefore(n,g)}}
          (window,document,"script","https://www.vol.at/wp-content/static/vol/datateam/whitedragon.js","hansrmdt"));
          hansrmdt('newTracker', 'spdt', 'https://spdt.vol.at', {appId: 'vn-at-web', postPath: '/rmdtsp/tp2', contexts: {webPage: true, gaCookies: true, clientHints: true, session: false,  performanceTiming: false}});
          $nuxt.$store.dispatch('tracking/sendLoginDataToSnowPlow')
        `,
      },
    ],
    marketing: [],
    preferences: [],
  }

  mounted() {
    this.handleGdpr()
    if (this.$store.getters['app/isApp']) {
      this.$store.dispatch('app/autoLogin')
    }
  }

  get isHomepage() {
    return this.$route.path === '/'
  }

  get isInMaintenanceMode() {
    return this.$config.inMaintenanceMode
  }

  handleGdpr(
    newVal: CookiebotConsent | undefined = undefined,
    oldVal: CookiebotConsent | undefined = undefined
  ): void {
    const difference = (
      o1: CookiebotConsent,
      o2: CookiebotConsent
    ): CookiebotConsent =>
      Object.entries(o2)
        .filter(
          ([key, value]) =>
            o1[key as keyof CookiebotConsent] !== value && key in o1
        )
        .reduce(
          (o, [key, value]) => ({ ...o, [key]: value }),
          {} as CookiebotConsent
        )

    let keys = Object.keys(this.gdprSettings)
    if (newVal && oldVal) {
      const changedValues = difference(oldVal, newVal)

      keys = Object.keys(changedValues)
    }

    keys.forEach((key) => {
      if (this.gdprSettings[key as keyof CookiebotConsent]) {
        if (
          typeof this.scriptsAfterGdpr[key as keyof CookiebotConsent] ===
          'undefined'
        )
          return
        this.scriptsAfterGdpr[key as keyof CookiebotConsent].forEach((s) => {
          const script = document.createElement(`script`)
          script.dataset.gdprCategory = key
          // script = { ...script, ...s }
          if (s.src) script.src = s.src
          if (s.async) script.async = s.async
          if (s.defer) script.defer = s.defer
          if (s.innerHTML) script.innerHTML = s.innerHTML
          if (s.type) script.type = s.type
          if (s.json) script.innerHTML = JSON.stringify(s.json)
          document.body.appendChild(script)
        })
      }
    })
  }

  get gdprSettings(): CookiebotConsent {
    return this.$store.getters['gdpr/consent']
  }

  @Watch('gdprSettings')
  onGdprSettingsChanged(newVal: CookiebotConsent, oldVal: CookiebotConsent) {
    console.log(`🍪 Consent changed`, newVal)
    this.handleGdpr(newVal, oldVal)
  }

  reload() {
    window.location.href = '/'
  }
}
