import Vue from 'vue'
import { toDate, parseISO, formatDistanceStrict } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import { entityDecode } from '~~/utils/entity-decode'

Vue.filter('timeForHumans', function (value) {
  const d = toDate(parseISO(`${value}+0`))
  return formatDistanceStrict(d, new Date(), {
    locale: deLocale,
    addSuffix: true,
  })
})

Vue.filter('entityDecode', function (value) {
  return entityDecode(value)
})

Vue.filter('toFormattedNumber', function (value) {
  if (!value || isNaN(value)) return ''
  return value.toLocaleString('de-de', { maximumFractionDigits: 2 })
})
