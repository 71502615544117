export default function ({ route, redirect }) {
  if (route.path.endsWith('.vn')) {
    const mappings = [
      { match: /^\/lokal\/(vorarlberg|chronik)/, replaceWith: '/$1' },
      { match: /^\/lokal\/(\d{4})/, replaceWith: '/vorarlberg/$1' },
    ]
    const matched = mappings.find((mapping) => route.path.match(mapping.match))
    if (matched) {
      const rewrittenUrl = route.path.replace(
        matched.match,
        matched.replaceWith
      )
      redirect(301, rewrittenUrl)
    }
  }
}
