<template>
  <div
    v-show="isIE"
    class="mx-4 mt-16 mb-6 rounded-lg bg-red px-3 py-3 text-base font-semibold text-white shadow"
  >
    Sie verwenden einen <strong>veralteten</strong> Browser. Wir empfehlen die
    Verwendung von
    <a
      class="underline hover:no-underline"
      href="https://www.mozilla.org/de/firefox/new/"
      target="_top"
    >
      Mozilla Firefox
    </a>
    oder
    <a
      href="https://www.google.com/intl/de/chrome/"
      target="_new"
      class="underline hover:no-underline"
    >
      Google Chrome
    </a>
    zur optimalen Nutzung von VN.at.
  </div>
</template>

<script>
export default {
  computed: {
    isIE() {
      return navigator.userAgent.match(/Trident/)
    },
  },
}
</script>
