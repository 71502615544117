<template>
  <component :is="headerType">
    <a v-if="isAbsolute" :href="permalink" target="_blank" v-html="title" />
    <nuxt-link v-else :to="permalink" v-html="title" />
  </component>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    permalink: {
      type: String,
      required: true,
    },
    isFirstItem: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    headerType() {
      return this.tag ? this.tag : this.isFirstItem ? 'h1' : 'h2'
    },
    isAbsolute() {
      return this.permalink.match(/^https?:\/\//) !== null
    },
  },
}
</script>
