export const actions = {
  async nuxtServerInit({ dispatch }) {
    dispatch(`gdpr/initializeGdpr`)
    if (!process.env.inMaintenanceMode) {
      await dispatch('frontpage/fetchFrontpageSettings')
      await dispatch('weather/initWeather')
    }
    await dispatch('app/initialize')
  },
}
