<template>
  <Modal :on-close="close">
    <Paywall v-if="showingLoginOrRegister" class="max-w-full" />
    <div v-else-if="showingData" class="piano-my-account">
      <div class="spinner">
        <div class="double-bounce1" />
        <div class="double-bounce2" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { paywallLog } from '~plugins/piano.js'
export default {
  computed: {
    showingLoginOrRegister() {
      return (
        this.$store.getters['paywall/showLoginForm'] ||
        this.$store.getters['paywall/showRegistrationForm'] ||
        this.$store.getters['paywall/showForgotPasswordForm'] ||
        this.$store.getters['paywall/showVOLIntegration'] ||
        this.$store.getters['paywall/showAbocardActivation']
      )
    },
    showingData() {
      return this.$store.getters['paywall/showData']
    },
  },
  methods: {
    close() {
      paywallLog(`should close paywall....`)
      this.$store.dispatch('paywall/hidePaywall')
    },
  },
}
</script>

<style>
.piano-my-account {
  @apply min-h-24 w-screen max-w-xl;
}
</style>
