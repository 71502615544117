
import { Component } from 'vue-property-decorator'
import BasePostType from '../../../../base/components/content/post-types/PostType.vue'

@Component
export default class PostType extends BasePostType {
  public get title() {
    if (
      this.post.excerpt &&
      this.post.author &&
      !['APA', 'VOL Import', 'Zeitungsimport VN'].includes(
        this.post.author.name
      )
    ) {
      return this.post.excerpt
    }

    return this.post.title
  }
}
