<template>
  <form class="forgot-password-form mb-0" method="POST" @submit.prevent="reset">
    <fieldset :disabled="loading" :aria-busy="loading">
      <div class="forgot-password-form__title paywall-form__title">
        {{ $t('paywall.forgot-password.teaser-header') }}
      </div>
      <div
        class="forgot-password-form__description paywall-form__description"
        v-html="$t('paywall.forgot-password.teaser-text')"
      />

      <ErrorMessage :error="error" />

      <label for="email">
        <input
          v-model="email"
          :placeholder="`${$t('paywall.forgot-password.email')}`"
          type="text"
          required
          name="email"
        />
      </label>

      <button type="submit">{{ $t('paywall.forgot-password.button') }}</button>
    </fieldset>
    <PaywallLinks for="forgotpassword" class="forgot-password-form__links" />
  </form>
</template>

<script>
import PaywallLinks from '~components/paywall/PaywallLinks.vue'

export default {
  components: {
    PaywallLinks,
  },
  data() {
    return {
      email: '',
      error: undefined,
    }
  },
  computed: {
    loading() {
      return this.$store.getters['paywall/fetchingData']
    },
  },
  methods: {
    async reset() {
      try {
        this.error = undefined
        await this.$store.dispatch('paywall/forgotPassword', {
          email: this.email,
        })
        this.$store.dispatch('paywall/hidePaywall')
      } catch (err) {
        this.error = err
      }
    },
  },
}
</script>
