<template>
  <BaseDetailedWeatherWidgetItem
    :style="{ 'background-color': temperatureColor }"
    v-bind="$props"
  />
</template>

<script>
import BaseDetailedWeatherWidgetItem from '../../../base/components/header/DetailedWeatherWidgetItem.vue'
import { weatherMixin } from '~base/mixins/weatherMixin.js'
export default {
  components: {
    BaseDetailedWeatherWidgetItem,
  },
  mixins: [weatherMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    detailData: {
      type: Array,
      required: true,
    },
    township: {
      type: String,
      required: true,
    },
    asButton: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
