// export * from '../../base/store/paywall.js'
import { enhanceState } from '../../base/store/utils.ts'
import { state, mutations, actions, getters } from '../../base/store/paywall.js'
import { paywallLog } from '../../base/plugins/piano.js'

const customerState = () => ({
  authorizedVideoUrl: undefined,
  authorizedVideoError: false,
})

const customerMutations = {
  SET_AUTH_VIDEO_URL(currentState, videoUrl) {
    currentState.authorizedVideoUrl = videoUrl
  },
  SET_AUTH_VIDEO_ERROR(currentState) {
    currentState.authorizedVideoError = true
  },
}

const customerActions = {
  async authorizeVideo({ commit }, { guid }) {
    try {
      paywallLog(`authorizing video with guid ${guid}`)
      const authQuery = require('~base/graphql/queries/AuthorizeApaVideo.gql')
      const apolloClient = this.app.apolloProvider.defaultClient
      const response = await apolloClient.query({
        query: authQuery,
        variables: {
          guid,
        },
        fetchPolicy: 'network-only',
      })
      if (response.data.apatoken.video) {
        commit('SET_AUTH_VIDEO_URL', response.data.apatoken.video)
      } else {
        commit('SET_AUTH_VIDEO_ERROR')
      }
    } catch (e) {
      console.error(`🔒 error fetching authorized video url`, e)
      commit('SET_AUTH_VIDEO_ERROR')
    }
  },
}

const customerGetters = {
  authorizedVideoUrl: ({ authorizedVideoUrl }) => authorizedVideoUrl,
  authorizedVideoError: ({ authorizedVideoError }) => authorizedVideoError,
}

const customState = enhanceState(state, customerState)
const customActions = { ...actions, ...customerActions }
const customMutations = { ...mutations, ...customerMutations }
const customGetters = { ...getters, ...customerGetters }

export {
  customState as state,
  customMutations as mutations,
  customActions as actions,
  customGetters as getters,
}
