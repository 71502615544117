<template>
  <div class="paywall-form">
    <header>
      <div class="paywall-headline" v-html="$t('paywall.header.title')" />
    </header>
    <div class="px-4 pt-4">
      <LoginForm v-if="$store.getters['paywall/showLoginForm']" />
      <RegistrationForm v-if="$store.getters['paywall/showRegistrationForm']" />
      <ForgotPasswordForm
        v-if="$store.getters['paywall/showForgotPasswordForm']"
      />
      <VOLIntegration v-if="$store.getters['paywall/showVOLIntegration']" />
      <AbocardActivation
        v-if="$store.getters['paywall/showAbocardActivation']"
        :activation-obj="$store.getters['paywall/aboCardActivationData']"
      />
    </div>
  </div>
</template>

<script>
import LoginForm from './LoginForm'
import RegistrationForm from './RegistrationForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import VOLIntegration from './VOLIntegration'
import AbocardActivation from './AbocardActivation'

export default {
  components: {
    LoginForm,
    RegistrationForm,
    ForgotPasswordForm,
    VOLIntegration,
    AbocardActivation,
  },
}
</script>

<style>
@import '~components/paywall/Paywall.css';
</style>
