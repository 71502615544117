<template>
  <transition name="transitionName">
    <div
      v-show="show"
      :class="`${$store.getters['modal/modalType']}-backdrop`"
      class="backdrop"
      @click="onCloseAction"
    >
      <div class="modal-slot-container">
        <div :class="cssClass" class="modal-slot" @click.stop>
          <CloseButton
            v-show="$store.getters['modal/canBeClosed']"
            :on-click="onCloseAction"
            class="modal-close-button"
          />
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    onClose: {
      type: Function,
      required: true,
    },
    name: {
      type: String,
      default: () => 'modal',
    },
  },
  computed: {
    show() {
      return (
        this.$store.getters['modal/isModalShowing'] &&
        this.$store.getters['modal/getName'] === this.name
      )
    },
    cssClass() {
      return this.$store.getters['modal/slotClass']
    },
    transitionName() {
      return `${this.$store.getters['modal/modalType']}-modal-transition`
    },
  },
  mounted() {
    const keyupListener = (e) => {
      if (e.keyCode === 27 && this.show) {
        this.onCloseAction()
      }
    }

    document.addEventListener('keyup', keyupListener)
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('keyup', keyupListener)
    })
  },
  updated() {
    if (document) {
      if (this.show) {
        document.body.classList.add('overflow-hidden')
        document.body.classList.remove('tp-modal-close')
      }
    }
  },
  methods: {
    onCloseAction() {
      if (document) {
        document.body.classList.remove('overflow-hidden')
      }
      if (!this.$store.getters['modal/canBeClosed']) {
        return
      }
      this.onClose()
    },
  },
}
</script>

<style>
@import '~components/utils/Modal.css';
</style>
