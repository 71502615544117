
























import { Component } from 'vue-property-decorator'
import PostType from '~components/content/post-types/PostType.vue'
import PostTitle from '~components/content/PostTitle.vue'

@Component({
  components: {
    PostTitle,
  },
})
export default class LegacyComment extends PostType {}
