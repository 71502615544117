<template>
  <OnClickOutside :do="() => $store.dispatch('weather/toggleWidget')">
    <div class="weather-widget mx-auto">
      <div class="weather-widget__container">
        <div class="weather-widget__data">
          <div class="bg-white">
            <DetailedWeatherWidgetItem
              :data="todayData"
              :detail-data="hourData"
              :township="township"
            ></DetailedWeatherWidgetItem>
            <WeatherWidgetItem
              v-for="d in nextDayData"
              :key="`${d.id}${d.dayofweek}`"
              class="weather-widget__data--day"
              :data="d"
            ></WeatherWidgetItem>
          </div>
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>

<script>
import DetailedWeatherWidgetItem from '~components/header/DetailedWeatherWidgetItem.vue'
import WeatherWidgetItem from '~components/header/WeatherWidgetItem.vue'

export default {
  components: {
    DetailedWeatherWidgetItem,
    WeatherWidgetItem,
  },
  computed: {
    regionData() {
      return this.$store.getters['weather/weatherData']
    },
    township() {
      return this.$store.getters['weather/selectedRegionNiceName']
    },
    hourData() {
      if (this.regionData.hourdata) {
        return this.regionData.hourdata.slice(0, 7)
      }
      return []
    },
    nextDayData() {
      return this.regionData.daydata.slice(1, 5)
    },
    todayData() {
      return this.regionData.daydata[0]
    },
  },
}
</script>

<style>
@import '~components/header/WeatherWidget.css';
</style>
