































































import { Vue, Component } from 'vue-property-decorator'
import Logo from '~components/header/Logo.vue'
import MenuButton from '~components/header/MenuButton.vue'
import MenuItem from '~components/header/MenuItem.vue'
import LoginLogout from '~components/header/LoginLogout.vue'
import WeatherButton from '~components/header/WeatherButton.vue'
import { Menu, MenuItem as IMenuItem } from '~base/common/types'
import { flatListToHierarchical } from '~plugins/utils'

@Component({
  components: {
    Logo,
    MenuButton,
    MenuItem,
    LoginLogout,
    WeatherButton,
  },
  apollo: {
    menu: {
      query: require('~base/graphql/queries/GetMenuByName.gql'),
      variables: {
        id: 'Header',
      },
      result({ data }) {
        if (!data || !data.menu) return
        this.menuItems = flatListToHierarchical(data.menu.menuItems.nodes)
      },
    },
  },
})
export default class Nav extends Vue {
  private menu: Menu = { menuItems: { nodes: [] } }
  private menuItems: IMenuItem[] = []

  get extendedMenuItems(): IMenuItem[] {
    return this.menuItems.map<IMenuItem>((menuItem: IMenuItem) => {
      if (menuItem.label === 'Service') {
        menuItem.childItems?.unshift({
          id: 'mydata',
          parentId: '',
          label: this.$t('menu.mydata').toString(),
          click: () => {
            this.$store.dispatch('paywall/showDataOverlay', {
              showCloseButton: true,
            })
          },
          cssClasses: [],
        })
      }
      return menuItem
    })
  }
}
