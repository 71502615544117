import { ActionTree, GetterTree, MutationTree } from 'vuex'

const defaultRegion = 'bregenz'
const defaultRegionNiceName = 'Bregenz'
export const state = () => ({
  symbol: 'A',
  temp: '',
  selectedRegion: undefined,
  data: {},
  showWidget: false,
})

export type RootState = ReturnType<typeof state>

export const townships = [
  ['Alberschwende', 'alberschwende'],
  ['Altach', 'altach'],
  ['Andelsbuch', 'andelsbuch'],
  ['Au', 'au'],
  ['Bartholom\u00E4berg', 'bartholomaeberg'],
  ['Bezau', 'bezau'],
  ['Bildstein', 'bildstein'],
  ['Bizau', 'bizau'],
  ['Blons', 'blons'],
  ['Bludenz', 'bludenz'],
  ['Bludesch', 'bludesch'],
  ['Brand', 'brand'],
  ['Bregenz', 'bregenz'],
  ['Buch', 'buch'],
  ['B\u00FCrs', 'buers'],
  ['B\u00FCrserberg', 'buerserberg'],
  ['Dalaas', 'dalaas'],
  ['Dam\u00FCls', 'damuels'],
  ['Doren', 'doren'],
  ['Dornbirn', 'dornbirn'],
  ['D\u00FCns', 'duens'],
  ['D\u00FCnserberg', 'duenserberg'],
  ['Egg', 'egg'],
  ['Eichenberg', 'eichenberg'],
  ['Feldkirch', 'feldkirch'],
  ['Fontanella', 'fontanella'],
  ['Frastanz', 'frastanz'],
  ['Fraxern', 'fraxern'],
  ['Fu\u00DFach', 'fussach'],
  ['Gai\u00DFau', 'gaissau'],
  ['Gaschurn', 'gaschurn'],
  ['G\u00F6fis', 'goefis'],
  ['G\u00F6tzis', 'goetzis'],
  ['Hard', 'hard'],
  ['Hittisau', 'hittisau'],
  ['H\u00F6chst', 'hoechst'],
  ['H\u00F6rbranz', 'hoerbranz'],
  ['Hohenems', 'hohenems'],
  ['Hohenweiler', 'hohenweiler'],
  ['Innerbraz', 'innerbraz'],
  ['Kennelbach', 'kennelbach'],
  ['Klaus', 'klaus'],
  ['Kl\u00F6sterle', 'kloesterle'],
  ['Koblach', 'koblach'],
  ['Krumbach', 'krumbach'],
  ['Langen bei Bregenz', 'langenbeibregenz'],
  ['Langenegg', 'langenegg'],
  ['Laterns', 'laterns'],
  ['Lauterach', 'lauterach'],
  ['Lech', 'lech'],
  ['Lingenau', 'lingenau'],
  ['Lochau', 'lochau'],
  ['Lor\u00FCns', 'loruens'],
  ['Ludesch', 'ludesch'],
  ['Lustenau', 'lustenau'],
  ['M\u00E4der', 'maeder'],
  ['Meiningen', 'meiningen'],
  ['Mellau', 'mellau'],
  ['Mittelberg', 'mittelberg'],
  ['M\u00F6ggers', 'moeggers'],
  ['Nenzing', 'nenzing'],
  ['N\u00FCziders', 'nueziders'],
  ['Raggal', 'raggal'],
  ['Rankweil', 'rankweil'],
  ['Reuthe', 'reuthe'],
  ['Riefensberg', 'riefensberg'],
  ['R\u00F6ns', 'roens'],
  ['R\u00F6this', 'roethis'],
  ['Sankt Anton im Montafon', 'stanton'],
  ['Sankt Gallenkirch', 'stgallenkirch'],
  ['Sankt Gerold', 'stgerold'],
  ['Satteins', 'satteins'],
  ['Schlins', 'schlins'],
  ['Schnepfau', 'schnepfau'],
  ['Schnifis', 'schnifis'],
  ['Schoppernau', 'schoppernau'],
  ['Schr\u00F6cken', 'schroecken'],
  ['Schruns', 'schruns'],
  ['Schwarzach', 'schwarzach'],
  ['Schwarzenberg', 'schwarzenberg'],
  ['Sibratsgf\u00E4ll', 'sibratsgfaell'],
  ['Silbertal', 'silbertal'],
  ['Sonntag', 'sonntag'],
  ['Stallehr', 'stallehr'],
  ['Sulz', 'sulz'],
  ['Sulzberg', 'sulzberg'],
  ['Th\u00FCringen', 'thueringen'],
  ['Th\u00FCringerberg', 'thueringerberg'],
  ['Tschagguns', 'tschagguns'],
  ['\u00DCbersaxen', 'uebersaxen'],
  ['Vandans', 'vandans'],
  ['Viktorsberg', 'viktorsberg'],
  ['Warth', 'warth'],
  ['Weiler', 'weiler'],
  ['Wolfurt', 'wolfurt'],
  ['Zwischenwasser', 'zwischenwasser'],
]

export const mutations: MutationTree<RootState> = {
  SET_SELECTED_REGION(currentState, { region, overwrite }) {
    if (!currentState.selectedRegion || overwrite) {
      currentState.selectedRegion = region
    }
  },
  SET_WEATHER_DATA(currentState, { temp, symbol, data }) {
    currentState.temp = temp
    currentState.symbol = symbol
    currentState.data = data
  },
  TOGGLE_WIDGET(currentState) {
    currentState.showWidget = !currentState.showWidget
  },
}

export const actions: ActionTree<RootState, RootState> = {
  async initWeather({ dispatch }) {
    let region = this.$cookies.get('weather-region')
    if (region) {
      if (region === 'übersaxen') {
        region = 'uebersaxen'
      }

      dispatch('setSelectedRegion', { region })
    }
    await dispatch('fetchWeatherData')
  },
  setSelectedRegion({ commit, getters }, { region, overwrite }) {
    if (!getters.selectedRegion || overwrite) {
      this.$cookies.set('weather-region', region, {
        maxAge: 365 * 24 * 60 * 60,
      })
    }
    commit('SET_SELECTED_REGION', { region, overwrite })
  },
  async fetchWeatherData({ commit, getters }) {
    let selectedRegion = getters.selectedRegion
    if (selectedRegion === 'uebersaxen') {
      selectedRegion = encodeURI('übersaxen')
    }
    try {
      const query = require('~base/graphql/queries/Weather.gql')
      const apolloClient = this.app.apolloProvider!.defaultClient
      if (apolloClient) {
        const response = await apolloClient.query({
          query,
          variables: {
            city: selectedRegion,
          },
        })

        if (response.data.weather) {
          const currentData = response.data.weather.daydata[0]
          commit('SET_WEATHER_DATA', {
            temp: currentData.temperature,
            symbol: currentData.weather_state,
            data: response.data.weather,
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  async changeRegion({ dispatch }, region) {
    dispatch('setRegion', { region })
    await dispatch('fetchWeatherData')
  },
  setRegion({ dispatch }, { region, overwrite = true }) {
    const regionSettings = townships.find(
      (t) => t[0] === region || t[1] === region
    )
    if (regionSettings) {
      dispatch('setSelectedRegion', { region: regionSettings[1], overwrite })
    }
  },
  toggleWidget({ commit }) {
    commit('TOGGLE_WIDGET')
  },
}

export const getters: GetterTree<RootState, RootState> = {
  temperature: (currentState) => currentState.temp,
  symbol: (currentState) => currentState.symbol,
  selectedRegion: ({ selectedRegion }) => selectedRegion || defaultRegion,
  selectedRegionNiceName: ({ selectedRegion }) => {
    const found = townships.find(
      (item) => item[1] === (selectedRegion || defaultRegion)
    )
    if (found) return found[0]
    return defaultRegionNiceName
  },
  weatherData: ({ data }) => data,
  showWidget: ({ showWidget }) => showWidget,
  townships: () => townships.map((a) => a[0]),
}
