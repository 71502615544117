const middleware = {}

middleware['check-bots'] = require('../themes/vn/middleware/check-bots.js')
middleware['check-bots'] = middleware['check-bots'].default || middleware['check-bots']

middleware['from-google-showcase'] = require('../themes/vn/middleware/from-google-showcase.js')
middleware['from-google-showcase'] = middleware['from-google-showcase'].default || middleware['from-google-showcase']

middleware['legacy-permalink'] = require('../themes/vn/middleware/legacy-permalink.js')
middleware['legacy-permalink'] = middleware['legacy-permalink'].default || middleware['legacy-permalink']

middleware['login-from-epaper'] = require('../themes/vn/middleware/login-from-epaper.js')
middleware['login-from-epaper'] = middleware['login-from-epaper'].default || middleware['login-from-epaper']

middleware['tracking-helper'] = require('../themes/vn/middleware/tracking-helper.js')
middleware['tracking-helper'] = middleware['tracking-helper'].default || middleware['tracking-helper']

export default middleware
