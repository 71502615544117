const deepMerge = require('deepmerge')
/* eslint-disable max-len, import/prefer-default-export */
export const get = (path: any, object: any) =>
  path.reduce(
    (objectAccumulator: any, pathString: string) =>
      objectAccumulator && objectAccumulator[pathString]
        ? objectAccumulator[pathString]
        : null,
    object
  )
/* eslint-enable max-len, import/prefer-default-export */
export const enhanceState = (state: any, custState: any) => {
  return () => deepMerge(state(), custState())
}
