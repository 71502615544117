<template>
  <li>
    <span v-if="!isLink" class="footer-link">{{ item.label }}</span>
    <a
      v-else-if="isExternalLink"
      :href="item.url"
      :target="item.target"
      :rel="item.target ? 'noreferrer' : ''"
      :title="item.title"
      :class="item.cssClassess && item.cssClasses.join(' ')"
      class="footer-link"
    >
      {{ item.label }}
    </a>
    <nuxt-link
      v-else
      :to="item.url"
      :title="item.title"
      :class="item.cssClassess && item.cssClasses.join(' ')"
      class="footer-link"
    >
      {{ item.label }}
    </nuxt-link>

    <ul
      v-if="hasChildren"
      class="submenu flex flex-row flex-wrap md:flex-col md:group-hover:block"
    >
      <FooterEntry
        v-for="submenuItem in item.childItems"
        :key="submenuItem.id"
        :item="submenuItem"
        class="mr-2"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'FooterEntry',
  components: {
    FooterEntry: this,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLink() {
      return this.item.url !== '#'
    },
    isExternalLink() {
      return this.item.url && this.item.url.startsWith('http')
    },
    hasChildren() {
      return this.item.childItems && this.item.childItems.length
    },
  },
}
</script>

<style>
@import '~components/footer/FooterEntry.css';
</style>
