



























import { Component } from 'vue-property-decorator'
import { applyFilters } from '@russmedia/hooks'
import BaseLoginLogout from '../../../base/components/header/LoginLogout.vue'
import { MenuItem as IMenuItem } from '~base/common/types'

@Component
export default class LoginLogout extends BaseLoginLogout {
  get register(): IMenuItem {
    return {
      id: '',
      parentId: '',
      label: this.$t('menu.register').toString(),
      cssClasses: ['register'],
      url: 'https://www.vol.at/abo-gratis?utm_source=vplus_vn&utm_medium=vplus_paywall&utm_campaign=vplus_verlinkung&utm_term=vplus_4wochen_gratis',
      target: '_blank',
    }
  }

  async onLoginClicked() {
    const onLoginFn = await applyFilters(
      'piano/onLoginFn',
      () => {
        this.$store.dispatch('menu/closeMenu')
        this.$store.dispatch('paywall/showLoginFormForDisplayMode', {
          showCloseButton: true,
          displayMode: 'modal',
        })
      },
      this.$store
    )
    onLoginFn()
  }

  async onLogoutClicked() {
    const onLogoutFn = await applyFilters(
      'piano/onLogoutFn',
      () => {
        this.$store.dispatch('paywall/logout')
      },
      this.$store
    )
    onLogoutFn()
  }

  get menuItem(): IMenuItem {
    if (!this.isLoggedIn) {
      return {
        id: '',
        parentId: '',
        label: this.$t('menu.login').toString(),
        cssClasses: [`login`],
        click: this.onLoginClicked,
      }
    }
    const item: IMenuItem = {
      id: '',
      parentId: '',
      cssClasses: ['my-account'],
      url: `#`,
      label:
        this.$store.getters['paywall/displayName'] ||
        this.$t('menu.myaccount').toString(),
    }
    if (!this.$store.getters['app/isApp']) {
      item.childItems = [
        {
          id: '',
          parentId: '',
          label: this.$t('menu.logout').toString(),
          url: ``,
          click: this.onLogoutClicked,
          cssClasses: [`logout`],
        },
      ]
    }
    return item
  }
}
