import Vue from 'vue'
import LazyHydrate from 'vue-lazy-hydration'
import SiteLogo from '~components/assets/SiteLogo.vue'
import Plus from '~components/assets/Plus.vue'
import Paywall from '~components/paywall/Paywall.vue'
import PaywallOverlay from '~components/paywall/PaywallOverlay.vue'
import ErrorMessage from '~components/utils/ErrorMessage.vue'
import Modal from '~components/utils/Modal.vue'
import Spinner from '~components/utils/Spinner.vue'
import CloseButton from '~components/utils/CloseButton.vue'
import Tabs from '~components/utils/Tabs.vue'
import Tab from '~components/utils/Tab.vue'
import OnClickOutside from '~components/utils/OnClickOutside.vue'
import DropDown from '~components/utils/DropDown.vue'
import Sidebar from '~components/sidebar/Sidebar.vue'

Vue.component('LazyHydrate', LazyHydrate)
Vue.component('SiteLogo', SiteLogo)
Vue.component('Plus', Plus)
Vue.component('Paywall', Paywall)
Vue.component('PaywallOverlay', PaywallOverlay)
Vue.component('ErrorMessage', ErrorMessage)
Vue.component('Modal', Modal)
Vue.component('Spinner', Spinner)
Vue.component('CloseButton', CloseButton)
Vue.component('Tabs', Tabs)
Vue.component('Tab', Tab)
Vue.component('OnClickOutside', OnClickOutside)
Vue.component('DropDown', DropDown)
Vue.component('Sidebar', Sidebar)
