


































import { Vue, Component } from 'vue-property-decorator'
import { AbocardActivationData } from '~base/common/types'
import PaywallLinks from '~components/paywall/PaywallLinks.vue'

@Component({
  components: {
    PaywallLinks,
  },
})
export default class LoginForm extends Vue {
  username: string = ''
  password: string = ''
  error: Error | null = null

  get loading() {
    return this.$store.getters['paywall/fetchingData']
  }

  async performLogin() {
    try {
      this.error = null
      await this.$store.dispatch('paywall/login', {
        username: this.username,
        password: this.password,
      })
      this.$store.dispatch('paywall/hidePaywall')
    } catch (err) {
      if (err.jsonData?.error?.data?.abocard?.cusno) {
        const activationObj: AbocardActivationData = {
          custno: err.jsonData.error.data.abocard.cusno,
          lastname: err.jsonData.error.data.abocard.card_lastname,
          email: err.jsonData.error.data.abocard.email,
        }
        this.$store.dispatch('paywall/showAbocardActivation', activationObj)
      } else {
        this.error = err
      }
    }
  }

  trackUpselling() {
    this.$store.dispatch('tracking/trackPaywallAction', 'upselling')
  }
}
