<template>
  <div class="menubutton">
    <button
      :class="{
        'on-background-image': onBackgroundImage,
      }"
      :title="$t('menu.toggle')"
      @click="$store.dispatch('menu/toggleMenu')"
    >
      <svg
        v-if="!$store.getters['menu/isOpen']"
        class="h-5 w-5 cursor-pointer fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
      <svg
        v-else
        class="h-4 w-4 cursor-pointer fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
        />
      </svg>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    onBackgroundImage: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>
@import '~components/header/MenuButton.css';
</style>
