
















































import { Component, Vue } from 'vue-property-decorator'
import { paywallError } from '~plugins/piano.js'

@Component
export default class VOLIntegration extends Vue {
  private error: Error | null = null
  private userHasAccess = false

  get loading() {
    return this.$store.getters['paywall/fetchingData']
  }

  async mounted() {
    await this.connect()
  }

  async connect() {
    try {
      this.error = null
      const response = await this.$store.dispatch('paywall/connectToVOL')
      if (response.hasAccess) {
        this.userHasAccess = true
        this.error = new Error(
          this.$t('paywall.volintegration.userhasaccess').toString()
        )
      } else {
        this.$store.dispatch('paywall/hidePaywall')
      }
    } catch (err) {
      paywallError(`Exception occurred`, err)
      this.error = err
    }
  }

  reload() {
    window.location.reload()
  }
}
