import { doAction, applyFilters } from '@russmedia/hooks'

let paywallVerbose = false

export function paywallLog(msg, ...rest) {
  if (paywallVerbose) {
    console.log(`🔒 ${msg}`, ...rest)
  }
}

export function paywallError(msg, ...rest) {
  if (paywallVerbose) {
    console.error(`🔒 ${msg}`, ...rest)
  }
}

export default async ({ app, store }) => {
  paywallVerbose = app.$config.paywallVerbose

  const paywallEnabled = await applyFilters(
    'piano/paywallEnabled',
    store.getters['paywall/paywallEnabled']
  )

  store.commit('paywall/SET_PAYWALL_ENABLED', paywallEnabled)

  if (!paywallEnabled) {
    paywallLog('paywall is disabled.')
    return
  }

  if (app.context.nuxtState.layout === 'empty') {
    return
  }

  await doAction('piano/pre-initialize')

  store.dispatch('paywall/initialize')

  app.router.beforeEach((_to, _from, next) => {
    // remove the overlay if it is shown.
    if (store.getters['paywall/showingOffer']) {
      store.dispatch('paywall/hidePianoOffers')
    }
    next()
  })

  if (app.$config.cxenseSiteId) {
    const cX = window.cX || { options: { consent: false } }
    cX.callQueue = cX.callQueue || []
    cX.callQueue.push(['setSiteId', app.$config.cxenseSiteId])
    cX.callQueue.push('sendPageViewEvent')
  }

  const tp = window.tp || []
  const userRef = store.getters['paywall/getUserRef']
  paywallLog(`stored userRef ${userRef}`)
  tp.push(['setUserRef', userRef])
  tp.push(['setAid', app.$config.pianoAppId])
  tp.push(['setSandbox', app.$config.pianoSandbox])
  tp.push(['setDebug', app.$config.pianoVerbose])

  const tags = await applyFilters('piano/customTags', ['free'])
  paywallLog(`sending tags`, tags)
  tp.push(['setTags', tags])

  if (store.getters['paywall/isBot']) {
    tp.push(['setCustomVariable', 'isBot', true])
  }

  const { ssoId: volSsoId = 0 } = app.$cookies.get('vol-integration') || {}
  tp.push(['setCustomVariable', 'vol_user', volSsoId])

  const customVariables = await applyFilters('piano/customVariables', [])
  customVariables.forEach((v) => {
    tp.push(['setCustomVariable', v.name, v.value])
  })

  tp.push([
    'addHandler',
    'meterActive',
    (meterData) => {
      paywallLog(`🎹 meterActive called`, meterData)
      store.dispatch('paywall/updateMeterData', meterData)
    },
  ])
  tp.push([
    'addHandler',
    'meterExpired',
    (meterData) => {
      paywallLog(`🎹 meterExpired called`, meterData)
      store.dispatch('paywall/updateMeterData', meterData)
      store.dispatch('paywall/meterExpired')
    },
  ])
  tp.push([
    'addHandler',
    'showOffer',
    (data) => {
      paywallLog(`🎹 showOffer called`, data)
      store.dispatch('paywall/showingPianoOffer', data)
    },
  ])
  tp.push([
    'addHandler',
    'showTemplate',
    (data) => {
      paywallLog(`🎹 showTemplate called`, data)
      store.dispatch('paywall/showingPianoTemplate', data)
    },
  ])
  tp.push([
    'addHandler',
    'experienceExecute',
    async (data) => {
      paywallLog(`🎹 experienceExecute called`, data)
      await store.dispatch('paywall/setExperienceData', data)
    },
  ])
  tp.push([
    'addHandler',
    'userSegmentTrue',
    (eventdata, moduledata, context) => {
      paywallLog(`🎹 userSegmentTrue called`, {
        eventdata,
        moduledata,
        context,
      })

      store.dispatch('paywall/userInSegment', {
        eventdata,
        moduledata,
        context,
      })
    },
  ])
  tp.push([
    'addHandler',
    'checkoutClose',
    async (data) => {
      paywallLog(`🎹 checkoutClose called`, data)
      const checkoutCloseHandler = await applyFilters(
        'piano/checkoutCloseHandler',
        'paywall/checkoutComplete'
      )
      store.dispatch(checkoutCloseHandler, { data })
    },
  ])
  tp.push([
    'addHandler',
    'checkoutStateChange',
    async ({ stateName }) => {
      paywallLog(`🎹 checkoutStateChange called`, stateName)
      const currentPost = store.getters['news/currentPost']
      store.dispatch('tracking/trackGeneric', {
        category: 'checkout',
        action: stateName,
        label: currentPost ? currentPost.title : document.title,
        nonInteraction: stateName === 'offer',
      })
      const vpvStates = {
        offer: { title: 'Checkout - Offer', path: '/checkout/offer' },
        state2: { title: 'Checkout - Payment', path: '/checkout/payment' },
        receipt: { title: 'Checkout - Complete', path: '/checkout/complete' },
      }
      const toTrack = vpvStates[stateName]
      if (toTrack) {
        store.dispatch('tracking/trackVirtualPageview', {
          force: true,
          virtualPagePath: toTrack.path,
          virtualPageTitle: toTrack.title,
          toOptimize: false,
        })
      }
      await doAction('piano/checkoutStateChange', stateName)
    },
  ])
  tp.push([
    'addHandler',
    'customEvent',
    async (data) => {
      paywallLog(`🎹 customEvent called`, data)
      const customEventHandler = await applyFilters(
        'piano/customEventHandler',
        'paywall/handleCustomEvent'
      )
      return store.dispatch(customEventHandler, data)
    },
  ])
  tp.push([
    'init',
    function () {
      paywallLog(`🎹 init called`)
      window.tp.experience.init()
    },
  ])
  tp.push([
    'addHandler',
    'startCheckout',
    (data) => {
      paywallLog(`🎹 startCheckout called`, data)
      paywallLog(`startCheckout triggered`, data)
    },
  ])
  tp.push([
    'addHandler',
    'loginRequired',
    async (data) => {
      paywallLog(`🎹 loginRequired called`, data)
      const loginRequiredHandler = await applyFilters(
        'piano/loginRequiredHandler',
        'paywall/showLoginFormForDisplayMode'
      )
      return store.dispatch(loginRequiredHandler, {
        data,
        callback: () => {
          paywallLog(`loginRequired callback executed`, data)
          if (data.reloadAfterLogin) {
            window.location.reload()
          }
        },
      })
    },
  ])
  window.tp = app.tp = tp
  window.addEventListener('message', (e) => {
    if (e.data && e.data.rmd) {
      paywallLog(`🎹 custom message received`, e)
      store.dispatch('paywall/handleMessageEvent', e.data.rmd)
    }
  })

  if (store.getters['app/isApp']) {
    ;(function (src) {
      const a = document.createElement('script')
      a.type = 'text/javascript'
      a.async = true
      a.src = src
      const b = document.getElementsByTagName('script')[0]
      b.parentNode.insertBefore(a, b)
    })(
      app.$config.pianoSandbox
        ? `//sandbox.tinypass.com/api/tinypass.min.js`
        : `//experience.tinypass.com/xbuilder/experience/load?aid=${app.$config.pianoAppId}`
    )
  }
}
