import _debounce from 'lodash.debounce'
const isModernBrowser = process.client && 'IntersectionObserver' in window

export const state = () => ({
  observerInitialized: false,
  observedEntries: [],
  lazyLoadedVisible: false,
  currentPost: undefined,
  inPreviewContext: false,
})

const triggerLazy = (entry) => {
  if (entry.dataset.isLoaded) return
  ;[...entry.attributes]
    .filter((attribute) => attribute.nodeName.startsWith('lazy-'))
    .map((attribute) => [
      attribute.nodeName.replace(/lazy-/, ''),
      attribute.nodeValue,
    ])
    .forEach(([name, value]) => {
      entry.setAttribute(name, value || '')
    })
  entry.dataset.isLoaded = true
}

export const mutations = {
  SET_CURRENT_POST(currentState, currentPost) {
    currentState.currentPost = currentPost
  },
  INITIALIZE_OBSERVER(currentState) {
    if (isModernBrowser) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
              triggerLazy(entry.target)
            }
          })
        },
        {
          rootMargin: '200px 0px',
          threshold: 0.1,
        }
      )
      window.lazyLoadingObserver = observer
    } else {
      const debouncedObserver = _debounce(
        () => {
          currentState.observedEntries.forEach((entry) => {
            if (
              !entry.dataset.isLoaded &&
              entry.offsetTop - 200 <
                (window.scrollY || window.pageYOffset) + screen.height
            ) {
              triggerLazy(entry)
            }
          })
        },
        250,
        { maxWait: 500 }
      )

      document.addEventListener('scroll', debouncedObserver)
      window.addEventListener('resize', debouncedObserver)
      window.addEventListener('orientationChange', debouncedObserver)
    }
    currentState.observerInitialized = true
  },
  OBSERVE(currentState, reference) {
    if (window.lazyLoadingObserver) {
      window.lazyLoadingObserver.observe(reference)
    } else {
      currentState.observedEntries.push(reference)
      if (currentState.observedEntries.length < 10) {
        triggerLazy(reference)
      }
    }
  },
  PAGE_CHANGED(currentState) {
    if (!isModernBrowser) {
      currentState.observerInitialized = false
      currentState.observedEntries = []
    }
  },
  IN_PREVIEW_CONTEXT(currentState) {
    currentState.inPreviewContext = true
  },
}

export const actions = {
  lazyLoadImage({ commit, state }, reference) {
    if (process.client) {
      if (!state.observerInitialized) {
        commit('INITIALIZE_OBSERVER')
      }
      commit('OBSERVE', reference)
    }
  },
  pageChanged({ commit }) {
    commit('PAGE_CHANGED')
  },
  setInPreviewContext({ commit }) {
    commit('IN_PREVIEW_CONTEXT')
  },
}

export const getters = {
  currentPost: (currentState) => currentState.currentPost,
  observedEntries: ({ observedEntries }) => observedEntries,
  inPreviewContext: ({ inPreviewContext }) => inPreviewContext,
  tagsSettings: () => ({}),
  categorySettings: () => ({}),
  searchSettings: () => ({}),
}
