<template>
  <form
    class="registration-form mb-0"
    method="POST"
    @submit.prevent="submitAction"
  >
    <div v-if="submitStep === 'preRegister'">
      <fieldset :disabled="loading" :aria-busy="loading">
        <div
          class="registration-form__title paywall-form__title"
          v-html="$t('paywall.register.form.title')"
        />

        <ErrorMessage :error="error" />
        <div class="mb-4">Mit Google anmelden</div>
        <div class="flex justify-between">
          <label for="firstname" class="w-full flex-shrink flex-grow">
            <input
              v-model="firstname"
              :placeholder="`${$t('paywall.register.form.firstname')} *`"
              type="firstname"
              required
              name="firstname"
            />
          </label>
          <div class="w-8" />
          <label for="lastname" class="w-full flex-shrink flex-grow">
            <input
              v-model="lastname"
              :placeholder="`${$t('paywall.register.form.lastname')} *`"
              type="lastname"
              required
              name="lastname"
            />
          </label>
        </div>

        <label for="email">
          <input
            v-model="email"
            :placeholder="`${$t('paywall.register.form.email')} *`"
            type="email"
            required
            name="email"
            @blur="checkIfEmailAlreadyExists"
          />
          <i18n
            v-if="showEmailHint"
            path="paywall.register.form.email-hint.text"
            tag="p"
            class="mt-2 px-2 text-base"
          >
            <span place="action" class="paywall-link">
              <a
                href="#"
                @click.prevent="$store.dispatch('paywall/showLoginForm')"
              >
                {{ $t('paywall.register.form.email-hint.link') }}
              </a>
            </span>
          </i18n>
        </label>

        <label for="phone">
          <input
            v-model="phone"
            :placeholder="`${$t('paywall.register.form.phone')} *`"
            type="tel"
            pattern="\+[1-9]{1}[0-9]{10,}"
            :title="$t('paywall.register.form.phone-title')"
            required
            name="phone"
          />
          <p class="mt-2 px-2 text-base">
            {{ $t('paywall.register.form.phone-verification') }}
          </p>
          <p v-if="phone.length !== 0" class="mt-2 px-2 text-base">
            {{ $t('paywall.register.form.phone-info') }}
          </p>
        </label>

        <label for="password">
          <input
            v-model="password"
            :placeholder="`${$t('paywall.register.form.password')} *`"
            type="password"
            required
            name="password"
          />
        </label>

        <div
          class="paywall-link text-black mb-4"
          v-html="$t('paywall.register.legal')"
        />
        <div v-for="item in agbList" :key="item.id" class="mb-4">
          <div class="flex">
            <input
              :id="`agb-${item.id}`"
              v-model="acceptedAgbs"
              :required="item.required"
              name="agbs"
              :value="item.id"
              type="checkbox"
            />
            <label
              :for="`agb-${item.id}`"
              class="paywall-link pl-1"
              v-html="prepareAgbText(item)"
            />
          </div>
        </div>

        <button type="submit">{{ $t('paywall.register.form.button') }}</button>
      </fieldset>

      <PaywallLinks v-if="showLinks" for="registration" />
    </div>

    <div v-if="submitStep === 'register'">
      <fieldset :disabled="loading" :aria-busy="loading">
        <div
          class="registration-form__title paywall-form__title"
          v-html="$t('paywall.register.form.sms-title')"
        />
        <div
          class="registration-form__description paywall-form__description"
          v-html="$t('paywall.register.form.sms-subtitle', { phone })"
        />

        <ErrorMessage :error="error" />
        <label for="token">
          <input
            v-model="token"
            :placeholder="`${$t('paywall.register.form.sms-token')} *`"
            type="text"
            required
            name="token"
          />
        </label>

        <button type="submit">
          {{ $t('paywall.register.form.sms-button') }}
        </button>
      </fieldset>

      <div class="paywall-form__links">
        <div class="paywall-link">
          <a
            href="#"
            @click.prevent="submitStep = 'preRegister'"
            v-html="$t('paywall.register.wrong-phone')"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import PaywallLinks from '~components/paywall/PaywallLinks.vue'
import { paywallError } from '~plugins/piano.js'
export default {
  components: {
    PaywallLinks,
  },
  props: {
    showLinks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      submitStep: 'preRegister',
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      password: '',
      token: '',
      error: undefined,
      agbList: [],
      acceptedAgbs: [],
    }
  },
  computed: {
    loading() {
      return this.$store.getters['paywall/fetchingData']
    },
    showEmailHint() {
      return this.$store.getters['paywall/showEmailHint']
    },
  },
  async mounted() {
    const { firstname, lastname, email } =
      this.$store.getters['paywall/prefillRegistrationData']
    if (firstname || lastname || email) {
      this.firstname = firstname || ''
      this.lastname = lastname || ''
      this.email = email || ''
    }
    this.agbList = await this.$store.dispatch('paywall/getAgbList', {})
  },
  methods: {
    submitAction() {
      this[this.submitStep]()
    },
    async preRegister() {
      try {
        this.error = undefined
        await this.$store.dispatch('paywall/preRegister', {
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          email: this.email,
          password: this.password,
          agbs: this.acceptedAgbs,
        })

        this.submitStep = 'register'
      } catch (err) {
        this.error = err
      }
    },
    async register() {
      try {
        this.error = undefined
        const json = await this.$store.dispatch('paywall/register', {
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          email: this.email,
          password: this.password,
          agbs: this.acceptedAgbs,
          token: this.token,
        })

        if (json.error) {
          // validation failed, go back to basic registration form
          if (json.error.data.step_back) {
            this.submitStep = 'preRegister'
          } else {
            // just invalid token, stay at verificiation form
            this.submitStep = 'register'
          }
          throw new Error(json.error.message)
        } else {
          // on success - hide paywall
          this.$store.dispatch('paywall/hidePaywall')
        }
      } catch (err) {
        paywallError(`Exception occurred`, err)
        this.error = err
      }
    },
    async checkIfEmailAlreadyExists() {
      await this.$store.dispatch('paywall/checkIfEmailAlreadyExists', {
        email: this.email,
      })
    },
    prepareAgbText(item) {
      return item.required ? `${item.agb} *` : item.agb
    },
  },
}
</script>
