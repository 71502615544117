<template>
  <footer role="contentinfo" class="site-footer">
    <div class="footer-container">
      <div class="copyright-container">
        &copy; {{ new Date().getFullYear() }} {{ $t('footer.copyright') }}
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style>
@import '~components/footer/Footer.css';
</style>
