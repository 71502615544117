














import { Component } from 'vue-property-decorator'
import DefaultLayout from '~base/layouts/default.vue'

@Component
export default class MitmachenLayout extends DefaultLayout {}
