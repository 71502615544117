<template>
  <span :class="additionalClasses" class="tab" @click="onClick">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    selectedClasses: {
      type: String,
      default() {
        return 'tab-selected'
      },
    },
  },
  computed: {
    isSelected() {
      return this.selectedTab === this.name
    },
    additionalClasses() {
      return this.isSelected ? this.selectedClasses : ''
    },
  },
  methods: {
    onClick() {
      this.$store.dispatch('tracking/trackGeneric', {
        category: 'datacenter',
        action: 'tabchanged',
        label: this.name,
      })
      this.$emit('tabchanged', this.name)
    },
  },
}
</script>

<style>
@import '~components/utils/Tab.css';
</style>
