import { ActionTree, GetterTree, MutationTree } from 'vuex'

export const state = () => ({
  pageType: `` as string,
  category: `` as string,
  consent: true as boolean,
})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  showPeronalisedAds({ commit }, showPeronalisedAds: boolean) {
    commit('SET_CONSENT', showPeronalisedAds)
  },
  pageChanged(
    { commit },
    { pageType = `` as string, category = `` as string }
  ) {
    commit('SET_PAGETYPE', pageType)
    commit('SET_CATEGORY', category)
  },
}

export const mutations: MutationTree<RootState> = {
  SET_PAGETYPE(currentState, data) {
    currentState.pageType = data
  },
  SET_CATEGORY(currentState, data) {
    currentState.category = data
  },
  SET_CONSENT(currentState, data) {
    currentState.consent = data
  },
}

export const getters: GetterTree<RootState, RootState> = {
  adParameters: ({ pageType, category, consent }) => ({
    pageType,
    category,
    consent,
  }),
}
