<template>
  <div class="detailed-weather-widget-item">
    <svg
      viewBox="0 0 20 20"
      role="presentation"
      class="current"
      v-html="getIcon(data.weather_state)"
    />

    <div class="py-2 px-4">
      <h2 class="flex items-center">
        <div v-html="$t('weather.today')"></div>
        <DropDown
          v-if="!asButton"
          :options="$store.getters['weather/townships']"
          :value="township"
          :placeholder="$t('weather.placeholder')"
          @input="onChange"
        ></DropDown>
        <div v-else>
          <div class="search-select-input">{{ township }}</div>
        </div>
      </h2>
      <div class="text-sm font-normal">{{ data.weather_text }}</div>
    </div>

    <div class="flex items-end py-2 px-4">
      <div class="temperature">{{ data.temperature }}°</div>
      <div class="text-sm">
        <p>
          <span class="font-semibold">{{ $t('weather.min') }}</span>
          {{ data.temperature_min }}°
        </p>
        <p>
          <span class="font-semibold">{{ $t('weather.max') }}</span>
          {{ data.temperature_max }}°
        </p>
        <p>
          <span class="font-semibold">{{ $t('weather.rain') }}</span>
          {{ data.rain_amount }} {{ $t('weather.rain_unit') }}
        </p>
        <p>
          <span class="font-semibold">{{ $t('weather.wind') }}</span>
          {{ data.wind_speed }} {{ $t('weather.wind_unit') }}
        </p>
      </div>
    </div>

    <div v-if="detailData.length > 0" class="details">
      <div
        v-for="detail in detailData"
        :key="detail.hour"
        class="flex flex-col items-center justify-center"
      >
        <div>{{ detail.hour }}:00</div>
        <svg
          viewBox="0 0 20 20"
          role="presentation"
          v-html="getIcon(detail.weather_state)"
        />
        <div>{{ detail.temperature }}°</div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown from '~components/utils/DropDown.vue'
import { weatherMixin } from '~base/mixins/weatherMixin.js'

export default {
  components: {
    DropDown,
  },
  mixins: [weatherMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    detailData: {
      type: Array,
      required: true,
    },
    township: {
      type: String,
      required: true,
    },
    asButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(e) {
      this.$store.dispatch('weather/changeRegion', e)
    },
  },
}
</script>

<style>
@import '~components/header/DetailedWeatherWidgetItem.css';
</style>
