import Vue from 'vue'

Vue.filter('ssoError', function (value) {
  const mappings = {
    // remove once SSO has been switched to German
    "Username and password doesn't match (1)":
      'Benutzername und Passwort stimmen nicht überein.',
    'User is unknown concerning client (1)':
      'Der angegebene Benutzer ist unbekannt. Bitte überprüfen Sie Ihre Eingabe.',
    'User is unknown concerning client (2)':
      'Der angegebene Benutzer ist unbekannt. Bitte überprüfen Sie Ihre Eingabe.',
    'Username still exists concerning client (1)':
      'Benutzer ist bereits vorhanden. Bitte melden Sie sich damit an',
    'User is not validated (email) (7)':
      'E-Mail-Adresse wurde noch nicht bestätigt. Bitte wenden Sie sich an den Kundenservice: <a href="mailto:abo@vn.at">abo@vn.at</a>',
    'user locked for client title (18)': 'Das Benutzerkonto ist gesperrt.',
    'no user with this abocardnumber. (218)':
      'Abonummer wurde noch nicht aktiviert. Bitte wenden Sie sich an den Kundenservice: <a href="mailto:abo@vn.at">abo@vn.at</a>',
    'Mandatory attributes missing: login (90)':
      'Bitte geben Sie Ihren Benutzernamen an.',
    'Mandatory attributes missing: password (90)':
      'Bitte geben Sie Ihr Passwort ein',
    // German SSO messages translation into normal language
    'Benutzername und Passwort stimmen nicht überein (1)':
      'Benutzername und Passwort stimmen nicht überein.',
    'Benutzer ist bzgl. des Mandanten unbekannt (1)':
      'Der angegebene Benutzer ist unbekannt. Bitte überprüfen Sie Ihre Eingabe.',
    'Benutzer ist bzgl. des Mandanten unbekannt (2)':
      'Der angegebene Benutzer ist unbekannt. Bitte überprüfen Sie Ihre Eingabe.',
    'Benutzername ist bzgl. des Mandanten schon vorhanden (1)':
      'Benutzer ist bereits vorhanden. Bitte melden Sie sich damit an',
    'Benutzer ist nicht validiert (E-Mail) (7)':
      'E-Mail-Adresse wurde noch nicht bestätigt. Bitte wenden Sie sich an den Kundenservice: <a href="mailto:abo@vn.at">abo@vn.at</a>',
    'Benutzer ist für Titelkennzeichen gesperrt. (18)':
      'Das Benutzerkonto ist gesperrt.',
    'Kein User mit dieser Abocardnr. vorhanden. (218)':
      'Abonummer wurde noch nicht aktiviert. Bitte wenden Sie sich an den Kundenservice: <a href="mailto:abo@vn.at">abo@vn.at</a>',
    'Pflichtattribute fehlen: login (90)':
      'Bitte geben Sie Ihren Benutzernamen an.',
    'Pflichtattribute fehlen: password (90)':
      'Bitte geben Sie Ihr Passwort ein',
    'Ihr Login enthält nicht erlaubte Zeichen (10)':
      'Die angegebene E-Mail-Adresse ist nicht gültig.',
  }
  if (Object.prototype.hasOwnProperty.call(mappings, value)) {
    return mappings[value]
  }
  return value
})
