<script>
export default {
  props: {
    do: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      listener: null,
    }
  },
  mounted() {
    this.listener = (e) => {
      if (e.target === this.$el || this.$el.contains(e.target)) {
        return
      }
      e.preventDefault()
      this.do()
    }

    setTimeout(() => {
      document.addEventListener('click', this.listener)
    }, 300)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.listener)
    delete this.listener
  },
  render() {
    return this.$slots.default[0]
  },
}
</script>
