



































import { Vue, Component, Prop } from 'vue-property-decorator'
import MenuItem from '~components/header/MenuItem.vue'
import { MenuItem as IMenuItem } from '~base/common/types'

@Component({
  components: { MenuItem },
})
export default class LoginLogout extends Vue {
  @Prop({ type: Boolean, default: false }) readonly short!: boolean

  get isLoggedIn(): boolean {
    return this.$store.getters['paywall/isLoggedIn']
  }

  get label(): string {
    return this.isLoggedIn
      ? this.$t('menu.logout').toString()
      : this.$t('menu.login').toString()
  }

  get register(): IMenuItem {
    return {
      id: '',
      parentId: '',
      label: this.$t('menu.register').toString(),
      cssClasses: ['register'],
      click: () => {
        this.$store.dispatch('menu/toggleMenu')
        this.$store.dispatch('paywall/showRegistrationOverlay', {
          showCloseButton: true,
        })
      },
    }
  }

  get registerMd(): IMenuItem {
    return {
      ...this.register,
      cssClasses: ['register-md'],
      label: this.$t('menu.register_md').toString(),
    }
  }

  get menuItem(): IMenuItem {
    if (!this.isLoggedIn) {
      return {
        id: '',
        parentId: '',
        label: this.$t('menu.login').toString(),
        cssClasses: [`login`],
        click: () => {
          this.$store.dispatch('menu/toggleMenu')
          this.$store.dispatch('paywall/showLoginOverlay', {
            showCloseButton: true,
          })
        },
      }
    }
    return {
      childItems: [
        {
          id: '',
          parentId: '',
          label: this.$t('menu.mydata').toString(),
          url: undefined,
          click: () => {
            this.$store.dispatch('paywall/showDataOverlay', {
              showCloseButton: true,
            })
          },
          cssClasses: [],
        },
        {
          id: '',
          parentId: '',
          label: this.$t('menu.logout').toString(),
          url: ``,
          click: () => {
            this.$store.dispatch('paywall/logout')
          },
          cssClasses: [`logout`],
        },
      ],
      id: '',
      parentId: '',
      cssClasses: ['my-account'],
      url: `#`,
      label:
        this.$store.getters['paywall/displayName'] || this.$t('menu.myaccount'),
    }
  }
}
