import { Machine, assign, AssignAction } from 'xstate'

export interface AppPaywallMachineSchema {
  states: {
    startup: {}
    doingOnboarding: {}
    doingLogin: {}
    doingActivation: {}
    doingUpsell: {}
    showingOffer: {
      states: {
        loading: {}
        ready: {}
        doingRegistration: {
          states: {
            showRegistrationForm: {}
            showTokenForm: {}
            showPostRegistration: {}
          }
        }
        doingUpgrade: {}
        error: {}
      }
    }
    forgotPassword: {}
    closing: {}
  }
}

interface AppPaywallMachineContext {
  custno: string | null
  lastname: string | null
  email: string | null
}

export type AppPaywallMachineEvent =
  | { type: 'CLOSE' }
  | { type: 'ONBOARDING' }
  | { type: 'LOGIN' }
  | { type: 'ACTIVATE'; custno: string | null; lastname: string | null }
  | { type: 'UPGRADE' }
  | { type: 'SHOW_OFFER' }
  | { type: 'SUCCESS' }
  | { type: 'FAIL' }
  | { type: 'REGISTER' }
  | { type: 'FORGOT_PASSWORD' }
  | { type: 'NEXT' }
  | { type: 'UPSELL' }

export const appPaywallMachine = Machine<
  AppPaywallMachineContext,
  AppPaywallMachineSchema,
  AppPaywallMachineEvent
>({
  key: 'appPaywallMachine',
  initial: 'startup',
  context: {
    custno: null,
    lastname: null,
    email: null,
  },
  states: {
    startup: {
      on: {
        ONBOARDING: 'doingOnboarding',
        LOGIN: 'doingLogin',
        SHOW_OFFER: 'showingOffer',
      },
    },
    doingOnboarding: {
      on: {
        LOGIN: 'doingLogin',
        SHOW_OFFER: 'showingOffer',
        CLOSE: 'closing',
      },
    },
    doingLogin: {
      on: {
        CLOSE: 'closing',
        FORGOT_PASSWORD: 'forgotPassword',
        ACTIVATE: {
          target: 'doingActivation',
          actions: ['setActivationContext'],
        },
        UPSELL: 'doingUpsell',
      },
    },
    doingActivation: {
      on: {
        CLOSE: 'closing',
        UPSELL: 'doingUpsell',
      },
    },
    doingUpsell: {
      on: {
        CLOSE: 'closing',
      },
    },
    showingOffer: {
      on: {
        CLOSE: 'closing',
      },
      initial: 'loading',
      states: {
        loading: {
          on: {
            SHOW_OFFER: 'ready',
            FAIL: 'error',
            UPGRADE: 'doingUpgrade',
          },
        },
        ready: {
          on: {
            REGISTER: 'doingRegistration',
            UPGRADE: 'doingUpgrade',
          },
        },
        doingRegistration: {
          initial: 'showRegistrationForm',
          states: {
            showRegistrationForm: {
              on: {
                NEXT: 'showTokenForm',
                LOGIN: { target: '../doingLogin', internal: false },
              },
            },
            showTokenForm: {
              on: {
                FAIL: 'showRegistrationForm',
                NEXT: 'showPostRegistration',
              },
            },
            showPostRegistration: {},
          },
        },
        doingUpgrade: {
          on: {},
        },
        error: {
          on: {},
        },
      },
    },
    forgotPassword: {
      on: {
        CLOSE: 'closing',
      },
    },
    closing: {
      type: 'final',
      entry: ['close'],
    },
  },
})

export const setActivationContext: AssignAction<
  AppPaywallMachineContext,
  AppPaywallMachineEvent
> = assign({
  custno: (_context: AppPaywallMachineContext, event: any) => event.custno,
  lastname: (_context: AppPaywallMachineContext, event: any) => event.lastname,
  email: (_context: AppPaywallMachineContext, event: any) => event.email,
})
