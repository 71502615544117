import {
  hasPicture as baseHasPicture,
  normalizeBlocks as baseNormalizeBlocks,
  enhance as baseEnhance,
  generatePermalink,
  getDescription,
} from '../../base/plugins/utils'
import {
  Post,
  EnhancedPost,
  Block,
  OpenGraphData,
  VueMetaProperty,
  Page,
} from '../common/types'

export {
  undomainify,
  getGroupedPosts,
  generateLdPlusJson,
  timeout,
  flatListToHierarchical,
} from '../../base/plugins/utils'

export const enhance = (
  p: Post | EnhancedPost,
  settings: any
): EnhancedPost | undefined => {
  if (!p) return
  const workingPost = <EnhancedPost>baseEnhance(p, settings)
  // remove images from the post if it is a comment
  if (workingPost.isComment && workingPost.blocks) {
    workingPost.blocks = workingPost.blocks.filter(
      (block: Block) =>
        !['russmedia/dcx-image', 'core/image'].includes(block.ot!)
    )
  }
  if (!workingPost) return
  workingPost.renderComponent = getRenderComponent(workingPost)
  return workingPost
}

export const getRenderComponent = (post: EnhancedPost): string => {
  if (post.isComment && !post.hasPicture) {
    return 'LegacyComment'
  }
  if (!post.hasPicture) {
    return 'NoPicture'
  }
  return 'Regular'
}

export const normalizeBlocks = baseNormalizeBlocks

export const hasPicture = baseHasPicture

export const generateOpenGraphMetaForPage = (p: Page, settings: any) => {
  const ogData: OpenGraphData = {
    ogImage: `${settings.publicUrl}/sharing/default-sharing.png`,
    title: p.title,
    description:
      p.content.replace(/(<([^>]+)>)/gi, '').substring(0, 300) + '...',
    author: p.author?.node.name || 'VN Redaktion',
    permalink: generatePermalink(p),
  }

  return getOpenGraphArray(ogData, settings)
}

export const generateOpenGraphMeta = (
  settings: any,
  p?: EnhancedPost
): VueMetaProperty[] => {
  let ogData: OpenGraphData
  ogData = {
    title: settings.siteName,
    permalink: settings.publicUrl!,
    description: settings.description,
    ogImage: `${settings.publicUrl}/sharing/default-sharing.png`,
    author: 'VN Redaktion',
  }
  if (p) {
    ogData = {
      title: p.title,
      permalink: p.permalink,
      description: getDescription(p),
      ogImage: `${settings.publicUrl}/meta/og${p.link}.jpg`,
      author: p.author?.node.name || 'VN Redaktion',
    }
  }
  return getOpenGraphArray(ogData, settings)
}

const getOpenGraphArray = (
  ogData: OpenGraphData,
  settings: any
): VueMetaProperty[] => {
  return [
    // { rel: 'canonical', hid: 'canonical', href: ogData.permalink },
    { hid: 'description', name: 'description', content: ogData.description },
    {
      hid: 'author',
      name: 'author',
      content: ogData.author,
    },
    // facebook
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: settings.siteName,
    },
    { hid: 'og:image', property: 'og:image', content: ogData.ogImage },
    { hid: 'og:image:url', property: 'og:image:url', content: ogData.ogImage },
    {
      hid: 'og:image:secure_url',
      property: 'og:image:secure_url',
      content: ogData.ogImage,
    },
    { hid: 'og:image:type', property: 'og:image:type', content: 'image/jpeg' },
    { hid: 'og:image:width', property: 'og:image:width', content: '1200' },
    { hid: 'og:image:height', property: 'og:image:height', content: '630' },
    { hid: 'og:title', property: 'og:title', content: ogData.title },
    {
      hid: 'og:description',
      property: 'og:description',
      content: ogData.description,
    },
    { hid: 'og:url', property: 'og:url', content: ogData.permalink },
    { hid: 'og:type', property: 'og:type', content: 'article' },
    { hid: 'fb:app_id', property: 'fb:app_id', content: settings.fbAppId! },

    // twitter
    {
      hid: 'twitter:image',
      property: 'twitter:image',
      content: ogData.ogImage,
    },
    {
      hid: 'twitter:card',
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      hid: 'twitter:site',
      property: 'twitter:site',
      content: settings.twitterHandle!,
    },
    {
      hid: 'twitter:url',
      property: 'twitter:url',
      content: ogData.permalink,
    },
    {
      hid: 'twitter:title',
      property: 'twitter:title',
      content: ogData.title,
    },
  ]
}
