<template>
  <div class="weather-widget-item flex flex-col justify-between">
    <div class="flex items-start justify-between">
      <div>
        <h2>{{ data.dayofweek }}</h2>
        <div class="text-xs font-normal">{{ data.weather_text }}</div>
      </div>
      <div>
        <svg
          viewBox="0 0 20 20"
          role="presentation"
          class="weather-button__icon"
          v-html="getIcon(data.weather_state)"
        />
      </div>
    </div>
    <div class="weather-widget-item__detail">
      <div class="weather-widget-item__temp">{{ data.temperature }}°</div>

      <div>
        <p class="text-right">
          <span class="font-semibold">{{ $t('weather.min') }}</span>
          {{ data.temperature_min }}°
        </p>
        <p class="text-right">
          <span class="font-semibold">{{ $t('weather.max') }}</span>
          {{ data.temperature_max }}°
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { weatherMixin } from '~base/mixins/weatherMixin.js'

export default {
  mixins: [weatherMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style>
@import '~components/header/WeatherWidgetItem.css';
</style>
